import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { HelixAssetUrlPipe } from '../helix-assets';

/**
 * Typing for the icon list.
 */
export interface IconList {
  [key: string]: string;
}

/**
 * These are all of the icons currently defined by helix. More icons can be added here as they are
 * available for helix. This returns the list of icons with the base path injected into the urls.
 *
 */
export function getIcons(): IconList {
  const icnBase = 'i/icn';
  return {
    // Core icons
    'activate-cancel': `${icnBase}/core/activate/activate-cancel.svg`,
    'activate-error': `${icnBase}/core/activate/activate-error.svg`,
    'activate-running': `${icnBase}/core/activate/activate-running.svg`,
    'activate-success': `${icnBase}/core/activate/activate-success.svg`,
    'activate-warning': `${icnBase}/core/activate/activate-warning.svg`,
    'activate': `${icnBase}/core/activate/activate.svg`,
    'activity': `${icnBase}/core/activity.svg`,
    'agent-scheduled': `${icnBase}/core/agent-scheduled.svg`,
    'anti-ransomeware': `${icnBase}/core/anti_ransomware.svg`,
    'archive-logs-restore': `${icnBase}/core/archive-logs-restore.svg`,
    'autoprotect-checkbox': `${icnBase}/core/autoprotect-checkbox.svg`,
    'autoprotect-checkbox-excluded': `${icnBase}/core/autoprotect-checkbox-excluded.svg`,
    'audit-logs': `${icnBase}/core/audit-log.svg`,
    'app-certified': `${icnBase}/core/app-certified.svg`,
    autoprotect: `${icnBase}/core/autoprotect.svg`,
    blackout: `${icnBase}/core/blackout.svg`,
    'bmr-backup': `${icnBase}/core/bmr-backup.svg`,
    'badge': `${icnBase}/core/badge.svg`,
    'badge-upgrade': `${icnBase}/core/badge_upgrade.svg`,
    'cdp-backup': `${icnBase}/core/cdp-backup.svg`,
    chassis: `${icnBase}/core/chassis.svg`,
    'agent-scheduled-alt': `${icnBase}/core/helix-agent-scheduled-alt.svg`,
    'agent-scheduled-alt-success': `${icnBase}/core/helix-agent-scheduled-alt-success.svg`,
    clone: `${icnBase}/core/clone.svg`,
    cloud: `${icnBase}/core/cloud.svg`,
    'cloud-backup': `${icnBase}/core/cloud-backup.svg`,
    'cloud-backup-cancel': `${icnBase}/core/cloud-backup-cancel.svg`,
    'cloud-backup-error': `${icnBase}/core/cloud-backup-error.svg`,
    'cloud-backup-running': `${icnBase}/core/cloud-backup-running.svg`,
    'cloud-backup-scheduled': `${icnBase}/core/cloud-backup-scheduled.svg`,
    'cloud-backup-skip': `${icnBase}/core/cloud-backup-skip.svg`,
    'cloud-backup-success': `${icnBase}/core/cloud-backup-success.svg`,
    'cloud-backup-warning': `${icnBase}/core/cloud-backup-warning.svg`,
    'cloud-cancel': `${icnBase}/core/cloud-cancel.svg`,
    'cloud-error': `${icnBase}/core/cloud-error.svg`,
    'cloud-running': `${icnBase}/core/cloud-running.svg`,
    'cloud-scheduled': `${icnBase}/core/cloud-scheduled.svg`,
    'cloud-skip': `${icnBase}/core/cloud-skip.svg`,
    'cloud-spin': `${icnBase}/core/cloud-spin.svg`,
    'cloud-spin-cancel': `${icnBase}/core/cloud-spin-cancel.svg`,
    'cloud-spin-error': `${icnBase}/core/cloud-spin-error.svg`,
    'cloud-spin-running': `${icnBase}/core/cloud-spin-running.svg`,
    'cloud-spin-scheduled': `${icnBase}/core/cloud-spin-scheduled.svg`,
    'cloud-spin-success': `${icnBase}/core/cloud-spin-success.svg`,
    'cloud-spin-warning': `${icnBase}/core/cloud-spin-warning.svg`,
    'cloud-success': `${icnBase}/core/cloud-success.svg`,
    'cloud-warning': `${icnBase}/core/cloud-warning.svg`,
    cluster: `${icnBase}/core/cluster.svg`,
    'cluster-cancel': `${icnBase}/core/cluster-cancel.svg`,
    'cluster-error': `${icnBase}/core/cluster-error.svg`,
    'cluster-full': `${icnBase}/core/cluster-full.svg`,
    'cluster-paused': `${icnBase}/core/cluster-paused.svg`,
    'cluster-protected': `${icnBase}/core/cluster-protected.svg`,
    'cluster-resting': `${icnBase}/core/cluster-resting.svg`,
    'cluster-running': `${icnBase}/core/cluster-running.svg`,
    'cluster-scheduled': `${icnBase}/core/cluster-scheduled.svg`,
    'cluster-skip': `${icnBase}/core/cluster-skip.svg`,
    'cluster-success': `${icnBase}/core/cluster-success.svg`,
    'cluster-warning': `${icnBase}/core/cluster-warning.svg`,
    'cohesity-cloud': `${icnBase}/core/cohesity-cloud.svg`,
    'cohesity-cloud-error': `${icnBase}/core/cohesity-cloud-error.svg`,
    'cohesity-cloud-map': `${icnBase}/core/cohesity-cloud-map.svg`,
    'cohesity-cloud-success': `${icnBase}/core/cohesity-cloud-success.svg`,
    'cohesity-cloud-warning': `${icnBase}/core/cohesity-cloud-warning.svg`,
    'cohesity-logo-s-gray': `${icnBase}/core/cohesity-logo-s-gray.svg`,
    configure: `${icnBase}/core/configure.svg`,
    connected: `${icnBase}/core/connected.svg`,
    connector: `${icnBase}/core/connector.svg`,
    'connector-alt': `${icnBase}/core/connector-alt.svg`,
    copy: `${icnBase}/core/copy.svg`,
    'dark-mode': `${icnBase}/core/dark-mode.svg`,
    'light': `${icnBase}/core/light.svg`,
    'apps': `${icnBase}/core/apps.svg`,
    'user-alt': `${icnBase}/core/user-alt.svg`,
    'help': `${icnBase}/core/help.svg`,
    'menu':`${icnBase}/core/menu.svg`,
    'data-pools': `${icnBase}/core/data-pools.svg`,
    'data-sensitivity': `${icnBase}/core/data-sensitivity.svg`,
    'data-sites': `${icnBase}/core/data-sites.svg`,
    'deletion-completed': `${icnBase}/core/deletion-completed.svg`,
    'deletion-in-progress': `${icnBase}/core/deletion-in-progress.svg`,
    'dr-activating': `${icnBase}/core/dr-activating.svg`,
    'dr-plans': `${icnBase}/core/dr-plans.svg`,
    database: `${icnBase}/core/database.svg`,
    db: `${icnBase}/core/db.svg`,
    'db-recovery-validation': `${icnBase}/core/db-recovery-validation.svg`,
    'db-restore': `${icnBase}/core/db-restore.svg`,
    'db-success': `${icnBase}/core/db-success.svg`,
    'db-instance': `${icnBase}/core/db-instance.svg`,
    'db-instance-auto': `${icnBase}/core/db-instance-auto.svg`,
    'db-instance-cancel': `${icnBase}/core/db-instance-cancel.svg`,
    'db-instance-error': `${icnBase}/core/db-instance-error.svg`,
    'db-instance-partial': `${icnBase}/core/db-instance-partial.svg`,
    'db-instance-protected': `${icnBase}/core/db-instance-protected.svg`,
    'db-instance-success': `${icnBase}/core/db-instance-success.svg`,
    'db-instance-warning': `${icnBase}/core/db-instance-warning.svg`,
    'delete-snapshot': `${icnBase}/core/delete-snapshot.svg`,
    diagnostics: `${icnBase}/core/diagnostics.svg`,
    disconnected: `${icnBase}/core/disconnected.svg`,
    download: `${icnBase}/core/download.svg`,
    'edit-outline': `${icnBase}/core/edit-outline.svg`,
    'extended-retention': `${icnBase}/core/extended-retention.svg`,
    failback: `${icnBase}/core/failback/failback.svg`,
    'failback-auto': `${icnBase}/core/failback/failback-auto.svg`,
    'failback-cancel': `${icnBase}/core/failback/failback-cancel.svg`,
    'failback-error': `${icnBase}/core/failback/failback-error.svg`,
    'failback-not-ready': `${icnBase}/core/failback/failback-not-ready.svg`,
    'failback-partial': `${icnBase}/core/failback/failback-partial.svg`,
    'failback-protected': `${icnBase}/core/failback/failback-protected.svg`,
    'failback-running': `${icnBase}/core/failback/failback-running.svg`,
    'failback-success': `${icnBase}/core/failback/failback-success.svg`,
    'failback-warn': `${icnBase}/core/failback/failback-warn.svg`,
    failover: `${icnBase}/core/failover/failover.svg`,
    'failover-auto': `${icnBase}/core/failover/failover-auto.svg`,
    'failover-cancel': `${icnBase}/core/failover/failover-cancel.svg`,
    'failover-error': `${icnBase}/core/failover/failover-error.svg`,
    'failover-not-ready': `${icnBase}/core/failover/failover-not-ready.svg`,
    'failover-partial': `${icnBase}/core/failover/failover-partial.svg`,
    'failover-protected': `${icnBase}/core/failover/failover-protected.svg`,
    'failover-running': `${icnBase}/core/failover/failover-running.svg`,
    'failover-success': `${icnBase}/core/failover/failover-success.svg`,
    'failover-warn': `${icnBase}/core/failover/failover-warn.svg`,
    'file-copy': `${icnBase}/core/file-copy.svg`,
    'file-services': `${icnBase}/core/file-services.svg`,
    'file-error': `${icnBase}/core/object-file-error.svg`,
    'file-success': `${icnBase}/core/object-file-success.svg`,
    'file-running': `${icnBase}/core/object-file-running.svg`,
    'file-warning': `${icnBase}/core/object-file-warning.svg`,
    'filter-local': `${icnBase}/core/filter-local.svg`,
    'filter-local-uninstall': `${icnBase}/core/filter-local-uninstall.svg`,
    'filter-local-upgrade': `${icnBase}/core/filter-local-upgrade.svg`,
    'gflag': `${icnBase}/core/gflag-recipes/gflags-resting.svg`,
    'gflag-success': `${icnBase}/core/gflag-recipes/gflags-success.svg`,
    'gflag-warning': `${icnBase}/core/gflag-recipes/gflags-warning.svg`,
    'gflag-cancel': `${icnBase}/core/gflag-recipes/gflags-cancel.svg`,
    'gflag-error': `${icnBase}/core/gflag-recipes/gflags-error.svg`,
    'half-eye': `${icnBase}/core/half-eye.svg`,
    helios: `${icnBase}/core/helios.svg`,
    hexagon: `${icnBase}/core/hexagon.svg`,
    'hierarchy-flat': `${icnBase}/core/hierarchy-flat.svg`,
    'hierarchy-folder': `${icnBase}/core/hierarchy-folder.svg`,
    'hierarchy-physical': `${icnBase}/core/hierarchy-physical.svg`,
    'hierarchy-tag': `${icnBase}/core/hierarchy-tag.svg`,
    indexing: `${icnBase}/core/indexing.svg`,
    'indexing-cancel': `${icnBase}/core/indexing-cancel.svg`,
    'indexing-error': `${icnBase}/core/indexing-error.svg`,
    'indexing-running': `${icnBase}/core/indexing-running.svg`,
    'indexing-scheduled': `${icnBase}/core/indexing-scheduled.svg`,
    'indexing-success': `${icnBase}/core/indexing-success.svg`,
    'indexing-warning': `${icnBase}/core/indexing-warning.svg`,
    infrastructure: `${icnBase}/core/infrastructure.svg`,
    'inventory': `${icnBase}/core/inventory.svg`,
    'infrastructure-alt': `${icnBase}/core/infrastructure-alt.svg`,
    'inventory-trolley': `${icnBase}/core/inventory-trolley.svg`,
    'inspect-bug': `${icnBase}/core/inspect-bug.svg`,
    'integration': `${icnBase}/core/integration.svg`,
    'integrations': `${icnBase}/core/integrations.svg`,
    'launch': `${icnBase}/core/launch.svg`,
    'infected-snapshot': `${icnBase}/core/security-center/infected-snapshot.svg`,
    'legal-hold-add': `${icnBase}/core/legal-hold-add.svg`,
    'legal-hold-remove': `${icnBase}/core/legal-hold-remove.svg`,
    'license-compliant': `${icnBase}/core/license-compliant.svg`,
    'license-non-compliant': `${icnBase}/core/license-non-compliant.svg`,
    'log-backup': `${icnBase}/core/log-backup.svg`,
    'lock-locked': `${icnBase}/core/lock-locked.svg`,
    'lock-unlocked': `${icnBase}/core/lock-unlocked.svg`,
    'meeting-room': `${icnBase}/core/meeting-room.svg`,
    'menu-open': `${icnBase}/core/menu-open.svg`,
    'map-visualization': `${icnBase}/core/map-visualization.svg`,
    nas: `${icnBase}/core/nas.svg`,
    'nav-collapsed': `${icnBase}/core/nav-collapsed.svg`,
    'nav-expanded': `${icnBase}/core/nav-expanded.svg`,
    node: `${icnBase}/core/node.svg`,
    paired: `${icnBase}/core/paired.svg`,
    'O365-mailbox-running': `${icnBase}/core/o365/O365-mailbox-running.svg`,
    'O365-mailbox-success': `${icnBase}/core/o365/O365-mailbox-success.svg`,
    'O365-mailbox-error': `${icnBase}/core/o365/O365-mailbox-error.svg`,
    'O365-mailbox-warning': `${icnBase}/core/o365/O365-mailbox-warning.svg`,
    'O365-site-list': `${icnBase}/core/o365/site-list.svg`,
    'open_in_new': `${icnBase}/core/open-in-new.svg`,
    protect: `${icnBase}/core/protect.svg`,
    'protect-partial': `${icnBase}/core/protect-partial.svg`,
    'protected-source': `${icnBase}/core/protected-source.svg`,
    protection: `${icnBase}/core/protection.svg`,
    policy: `${icnBase}/core/policy.svg`,
    'policy-success': `${icnBase}/core/policy-success.svg`,
    'prepare-failback-auto': `${icnBase}/core/prepare-failback/prepare-failback-auto.svg`,
    'prepare-failback-cancel': `${icnBase}/core/prepare-failback/prepare-failback-cancel.svg`,
    'prepare-failback-deleted': `${icnBase}/core/prepare-failback/prepare-failback-deleted.svg`,
    'prepare-failback-deletion-failed': `${icnBase}/core/prepare-failback/prepare-failback-deletion-failed.svg`,
    'prepare-failback-error': `${icnBase}/core/prepare-failback/prepare-failback-error.svg`,
    'prepare-failback-not-ready': `${icnBase}/core/prepare-failback/prepare-failback-not-ready.svg`,
    'prepare-failback-partial': `${icnBase}/core/prepare-failback/prepare-failback-partial.svg`,
    'prepare-failback-protected': `${icnBase}/core/prepare-failback/prepare-failback-protected.svg`,
    'prepare-failback-running': `${icnBase}/core/prepare-failback/prepare-failback-running.svg`,
    'prepare-failback-success': `${icnBase}/core/prepare-failback/prepare-failback-success.svg`,
    'prepare-failback-warn': `${icnBase}/core/prepare-failback/prepare-failback-warn.svg`,
    'prepare-failback': `${icnBase}/core/prepare-failback/prepare-failback.svg`,
    'prepare-failover-cancel': `${icnBase}/core/failover/prepare-failover-cancel.svg`,
    'prepare-failover-failed': `${icnBase}/core/failover/prepare-failover-failed.svg`,
    'prepare-failover-running': `${icnBase}/core/failover/prepare-failover-running.svg`,
    'prepare-failover-success': `${icnBase}/core/failover/prepare-failover-success.svg`,
    'protection-group': `${icnBase}/core/protection-group.svg`,
    'protection-group-skip': `${icnBase}/core/protection-group-skip.svg`,
    'protection-group-success': `${icnBase}/core/protection-group-success.svg`,
    'protection-policy': `${icnBase}/core/protection-policy.svg`,
    'protection-policy-global': `${icnBase}/core/protection-policy-global.svg`,
    'protection-policy-scheduled': `${icnBase}/core/protection-policy-scheduled.svg`,
    'recipe': `${icnBase}/core/gflag-recipes/recipe-resting.svg`,
    'recipe-success': `${icnBase}/core/gflag-recipes/recipe-success.svg`,
    'recipe-warning': `${icnBase}/core/gflag-recipes/recipe-warning.svg`,
    'recipe-cancel': `${icnBase}/core/gflag-recipes/recipe-cancel.svg`,
    'recipe-error': `${icnBase}/core/gflag-recipes/recipe-error.svg`,
    recover: `${icnBase}/core/recover.svg`,
    'recover-cancel': `${icnBase}/core/recover-cancel.svg`,
    'recover-error': `${icnBase}/core/recover-error.svg`,
    'recover-running': `${icnBase}/core/recover-running.svg`,
    'recover-scheduled': `${icnBase}/core/recover-scheduled.svg`,
    'recover-success': `${icnBase}/core/recover-success.svg`,
    'recover-warning': `${icnBase}/core/recover-warning.svg`,
    replication: `${icnBase}/core/replication.svg`,
    'replication-cancel': `${icnBase}/core/replication-cancel.svg`,
    'replication-error': `${icnBase}/core/replication-error.svg`,
    'replication-running': `${icnBase}/core/replication-running.svg`,
    'replication-scheduled': `${icnBase}/core/replication-scheduled.svg`,
    'replication-success': `${icnBase}/core/replication-success.svg`,
    'replication-warning': `${icnBase}/core/replication-warning.svg`,
    'edit-doc': `${icnBase}/core/edit-doc.svg`,
    'snapshot-deleted': `${icnBase}/core/snapshot-deleted.svg`,
    'snapshot': `${icnBase}/core/snapshot.svg`,
    retry: `${icnBase}/core/retry.svg`,
    'saas-connection': `${icnBase}/core/saas-connection.svg`,
    'fingerprint': `${icnBase}/core/fingerprint.svg`,
    security: `${icnBase}/core/security.svg`,
    'security-center-data-classification-sku': `${icnBase}/core/security-center/data-classification-sku.svg`,
    'security-center-data-sensitivity-blank-card': `${icnBase}/core/security-center/data-sensitivity-blank-card.svg`,
    'security-center-pay-wall': `${icnBase}/core/security-center/paywall.svg`,
    'security-center-threat-protection-sku': `${icnBase}/core/security-center/threat-protection-sku.svg`,
    'security-center-vault': `${icnBase}/core/security-center/vault.svg`,
    'sensivity-data-posture': `${icnBase}/core/security-center/sensivity-data-posture.svg`,
    'user-activity': `${icnBase}/core/security-center/user-activity.svg`,
    'security-posture': `${icnBase}/core/security-center/security-posture.svg`,
    server: `${icnBase}/core/server.svg`,
    sitecontinuity: `${icnBase}/core/sitecontinuity.svg`,
    sla: `${icnBase}/core/sla.svg`,
    'sla-cancel': `${icnBase}/core/sla-cancel.svg`,
    'sla-error': `${icnBase}/core/sla-error.svg`,
    'sla-running': `${icnBase}/core/sla-running.svg`,
    'sla-scheduled': `${icnBase}/core/sla-scheduled.svg`,
    'sla-success': `${icnBase}/core/sla-success.svg`,
    'sla-warning': `${icnBase}/core/sla-warning.svg`,
    'smart-erase': `${icnBase}/core/smart-erase.svg`,
    snowflake: `${icnBase}/core/snowflake.svg`,
    'storage-array-snapshot': `${icnBase}/core/storage-array-snapshot.svg`,
    system: `${icnBase}/core/system.svg`,
    'sym-link': `${icnBase}/core/sym-link.svg`,
    'system-helios': `${icnBase}/core/system-helios.svg`,
    tape: `${icnBase}/core/tape.svg`,
    'tape-cancel': `${icnBase}/core/tape-cancel.svg`,
    'tape-error': `${icnBase}/core/tape-error.svg`,
    'tape-running': `${icnBase}/core/tape-running.svg`,
    'tape-scheduled': `${icnBase}/core/tape-scheduled.svg`,
    'tape-skip': `${icnBase}/core/tape-skip.svg`,
    'tape-success': `${icnBase}/core/tape-success.svg`,
    'tape-warning': `${icnBase}/core/tape-warning.svg`,
    teardown: `${icnBase}/core/teardown/teardown.svg`,
    'teardown-auto': `${icnBase}/core/teardown/teardown-auto.svg`,
    'teardown-cancel': `${icnBase}/core/teardown/teardown-cancel.svg`,
    'teardown-error': `${icnBase}/core/teardown/teardown-error.svg`,
    'teardown-partial': `${icnBase}/core/teardown/teardown-partial.svg`,
    'teardown-protected': `${icnBase}/core/teardown/teardown-protected.svg`,
    'teardown-running': `${icnBase}/core/teardown/teardown-running.svg`,
    'teardown-success': `${icnBase}/core/teardown/teardown-success.svg`,
    'teardown-warn': `${icnBase}/core/teardown/teardown-warn.svg`,
    'threat-protection': `${icnBase}/core/threat-protection.svg`,
    unpaired: `${icnBase}/core/unpaired.svg`,
    upgrade: `${icnBase}/core/upgrade.svg`,
    patch: `${icnBase}/core/patch.svg`,
    plug: `${icnBase}/core/plug.svg`,
    vault: `${icnBase}/core/vault.svg`,
    'vault-cancel': `${icnBase}/core/vault-cancel.svg`,
    'vault-error': `${icnBase}/core/vault-error.svg`,
    'vault-running': `${icnBase}/core/vault-running.svg`,
    'vault-skip': `${icnBase}/core/vault-skip.svg`,
    'vault-success': `${icnBase}/core/vault-success.svg`,
    'vault-warning': `${icnBase}/core/vault-warning.svg`,
    'vault-cold': `${icnBase}/core/vault-cold.svg`,
    'vault-warm': `${icnBase}/core/vault-warm.svg`,
    'vault-lock': `${icnBase}/core/vault-lock.svg`,
    'vault-unlock': `${icnBase}/core/vault-unlock.svg`,
    'vault-protected': `${icnBase}/core/vault-protected.svg`,
    'vault-auto': `${icnBase}/core/vault-auto.svg`,
    'vault-partial': `${icnBase}/core/vault-partial.svg`,
    'vault-scheduled': `${icnBase}/core/vault-scheduled.svg`,
    'vault-search': `${icnBase}/core/vault-search.svg`,
    'vault-transfer': `${icnBase}/core/vault-transfer.svg`,
    vm: `${icnBase}/core/vm.svg`,
    'vm-success': `${icnBase}/core/vm-success.svg`,
    volume: `${icnBase}/core/volume.svg`,
    'volume-success': `${icnBase}/core/volume-success.svg`,
    view: `${icnBase}/core/view.svg`,

    // Protection Group - will be used in other context
    'group-application': `${icnBase}/group/group-application.svg`,
    'group-azure': `${icnBase}/group/group-azure.svg`,
    'group-aws': `${icnBase}/group/group-aws.svg`,
    'group-entra-id': `${icnBase}/group/group-entra-id.svg`,
    'group-database': `${icnBase}/group/group-database.svg`,
    'group-file': `${icnBase}/group/group-file.svg`,
    'group-generic': `${icnBase}/group/group-generic.svg`,
    'group-hadoop': `${icnBase}/group/group-hadoop.svg`,
    'group-instant-volume-mount': `${icnBase}/group/group-instant-volume-mount.svg`,
    'group-kubernetes': `${icnBase}/group/group-kubernetes.svg`,
    'group-nas': `${icnBase}/group/group-nas.svg`,
    'group-physical-server': `${icnBase}/group/group-physical-server.svg`,
    'group-remote-adapter': `${icnBase}/group/group-remote-adapter.svg`,
    'group-storage-array': `${icnBase}/group/group-storage-array.svg`,
    'group-storage-volume': `${icnBase}/group/group-storage-volume.svg`,
    'group-view': `${icnBase}/group/group-view.svg`,
    'group-virtual-machine': `${icnBase}/group/group-virtual-machine.svg`,
    'group-salesforce': `${icnBase}/group/group-salesforce.svg`,
    'group-bucket': `${icnBase}/group/group-bucket.svg`,

    // Protection Objects - will be used in other context
    'object-ad-auto': `${icnBase}/object/ad/object-ad-auto.svg`,
    'object-ad-partial': `${icnBase}/object/ad/object-ad-partial.svg`,
    'object-ad-protected': `${icnBase}/object/ad/object-ad-protected.svg`,
    'object-ad': `${icnBase}/object/ad/object-ad.svg`,
    'object-ad-applications': `${icnBase}/object/ad-applications/object-ad-applications.svg`,
    'object-ad-dns': `${icnBase}/object/ad-dns/object-ad-dns.svg`,
    'object-ad-org': `${icnBase}/object/ad-org/object-ad-org.svg`,
    'object-agent-management': `${icnBase}/object/agent-management/object-agent-management.svg`,
    'object-aix-auto': `${icnBase}/object/aix/object-aix-auto.svg`,
    'object-aix-partial': `${icnBase}/object/aix/object-aix-partial.svg`,
    'object-aix-protected': `${icnBase}/object/aix/object-aix-protected.svg`,
    'object-aix': `${icnBase}/object/aix/object-aix.svg`,
    'object-aws-auto': `${icnBase}/object/aws/object-aws-auto.svg`,
    'object-aws-partial': `${icnBase}/object/aws/object-aws-partial.svg`,
    'object-aws-protected': `${icnBase}/object/aws/object-aws-protected.svg`,
    'object-aws': `${icnBase}/object/aws/object-aws.svg`,
    'object-aws-rds-auto': `${icnBase}/object/aws-db/object-rds-auto.svg`,
    'object-aws-rds-cancel': `${icnBase}/object/aws-db/object-rds-cancel.svg`,
    'object-aws-rds-error': `${icnBase}/object/aws-db/object-rds-error.svg`,
    'object-aws-rds-partial': `${icnBase}/object/aws-db/object-rds-partial.svg`,
    'object-aws-rds-protected': `${icnBase}/object/aws-db/object-rds-protected.svg`,
    'object-aws-rds-running': `${icnBase}/object/aws-db/object-rds-running.svg`,
    'object-aws-rds-success': `${icnBase}/object/aws-db/object-rds-success.svg`,
    'object-aws-rds-warning': `${icnBase}/object/aws-db/object-rds-warning.svg`,
    'object-aws-rds': `${icnBase}/object/aws-db/object-rds.svg`,
    'object-aws-aurora-auto': `${icnBase}/object/aws-db/object-aurora-auto.svg`,
    'object-aws-aurora-cancel': `${icnBase}/object/aws-db/object-aurora-cancel.svg`,
    'object-aws-aurora-error': `${icnBase}/object/aws-db/object-aurora-error.svg`,
    'object-aws-aurora-partial': `${icnBase}/object/aws-db/object-aurora-partial.svg`,
    'object-aws-aurora-protected': `${icnBase}/object/aws-db/object-aurora-protected.svg`,
    'object-aws-aurora-running': `${icnBase}/object/aws-db/object-aurora-running.svg`,
    'object-aws-aurora-success': `${icnBase}/object/aws-db/object-aurora-success.svg`,
    'object-aws-aurora-warning': `${icnBase}/object/aws-db/object-aurora-warning.svg`,
    'object-aws-aurora': `${icnBase}/object/aws-db/object-aurora.svg`,
    'object-aws-s3': `${icnBase}/object/aws/object-aws-s3.svg`,
    'object-aws-s3-auto': `${icnBase}/object/aws/object-aws-s3-auto.svg`,
    'object-aws-s3-partial': `${icnBase}/object/aws/object-aws-s3-partial.svg`,
    'object-aws-s3-protected': `${icnBase}/object/aws/object-aws-s3-protected.svg`,
    'object-aws-s3-success': `${icnBase}/object/aws/object-aws-s3-success.svg`,

    // AWS VMs
    'object-aws-vm-windows': `${icnBase}/object/aws/vm-windows/object-vm-windows.svg`,
    'object-aws-vm-windows-auto': `${icnBase}/object/aws/vm-windows/object-vm-windows-auto.svg`,
    'object-aws-vm-windows-partial': `${icnBase}/object/aws/vm-windows/object-vm-windows-partial.svg`,
    'object-aws-vm-windows-protected': `${icnBase}/object/aws/vm-windows/object-vm-windows-protected.svg`,
    'object-aws-vm': `${icnBase}/object/aws/vm/object-vm.svg`,
    'object-aws-vm-auto': `${icnBase}/object/aws/vm/object-vm-auto.svg`,
    'object-aws-vm-partial': `${icnBase}/object/aws/vm/object-vm-partial.svg`,
    'object-aws-vm-protected': `${icnBase}/object/aws/vm/object-vm-protected.svg`,

    // AWS AV Zone & Region
    'object-aws-availability-zone': `${icnBase}/object/aws/availability-zone/object-aws-availability-zone.svg`,
    'object-aws-availability-zone-auto': `${icnBase}/object/aws/availability-zone/object-aws-availability-zone-auto.svg`,
    'object-aws-availability-zone-partial': `${icnBase}/object/aws/availability-zone/object-aws-availability-zone-partial.svg`,
    'object-aws-availability-zone-protected': `${icnBase}/object/aws/availability-zone/object-aws-availability-zone-protected.svg`,
    'object-aws-region': `${icnBase}/object/aws/region/object-aws-region.svg`,
    'object-aws-region-auto': `${icnBase}/object/aws/region/object-aws-region-auto.svg`,
    'object-aws-region-partial': `${icnBase}/object/aws/region/object-aws-region-partial.svg`,
    'object-aws-region-protected': `${icnBase}/object/aws/region/object-aws-region-protected.svg`,
    'object-azure-auto': `${icnBase}/object/azure/object-azure-auto.svg`,
    'object-azure-partial': `${icnBase}/object/azure/object-azure-partial.svg`,
    'object-azure-protected': `${icnBase}/object/azure/object-azure-protected.svg`,
    'object-azure-resting': `${icnBase}/object/azure/object-azure-resting.svg`,
    'object-azure-resting-partial': `${icnBase}/object/azure/object-azure-resting.svg`,
    'object-azure-resource-group-resting': `${icnBase}/object/azure/object-azure-resource-group-resting.svg`,
    'object-azure-subscriptions-resting': `${icnBase}/object/azure/object-azure-subscriptions-resting.svg`,
    'object-azure': `${icnBase}/object/azure/object-azure.svg`,
    'object-cassandra-cluster': `${icnBase}/object/cassandra/object-cluster.svg`,
    'object-cd-organization': `${icnBase}/object/cd-organization/object-cd-organization.svg`,
    'object-cluster-auto': `${icnBase}/object/cluster/object-cluster-auto.svg`,
    'object-cluster-partial': `${icnBase}/object/cluster/object-cluster-partial.svg`,
    'object-cluster-protected-alt': `${icnBase}/object/cluster/object-cluster-protected-alt.svg`,
    'object-cluster-protected': `${icnBase}/object/cluster/object-cluster-protected.svg`,
    'object-cluster': `${icnBase}/object/cluster/object-cluster.svg`,
    'object-datacenter-auto': `${icnBase}/object/datacenter/object-datacenter-auto.svg`,
    'object-datacenter-partial': `${icnBase}/object/datacenter/object-datacenter-partial.svg`,
    'object-datacenter-protected': `${icnBase}/object/datacenter/object-datacenter-protected.svg`,
    'object-datacenter': `${icnBase}/object/datacenter/object-datacenter.svg`,
    'object-datastore-auto': `${icnBase}/object/datastore/object-datastore-auto.svg`,
    'object-datastore-partial': `${icnBase}/object/datastore/object-datastore-partial.svg`,
    'object-datastore-protected': `${icnBase}/object/datastore/object-datastore-protected.svg`,
    'object-datastore': `${icnBase}/object/datastore/object-datastore.svg`,
    'object-db': `${icnBase}/object/db/object-db.svg`,
    'object-db-auto': `${icnBase}/object/db/object-db-auto.svg`,
    'object-db-clone': `${icnBase}/object/db/object-db-clone.svg`,
    'object-db-partial': `${icnBase}/object/db/object-db-partial.svg`,
    'object-db-protected': `${icnBase}/object/db/object-db-protected.svg`,
    'object-db-skip': `${icnBase}/object/db/object-db-skip.svg`,
    'object-db-success': `${icnBase}/core/db-success.svg`,
    'object-email-auto': `${icnBase}/object/email/object-email-auto.svg`,
    'object-email-partial': `${icnBase}/object/email/object-email-partial.svg`,
    'object-email-protected': `${icnBase}/object/email/object-email-protected.svg`,
    'object-email-skip': `${icnBase}/object/email/object-email-skip.svg`,
    'object-email': `${icnBase}/object/email/object-email.svg`,
    'object-entra-id': `${icnBase}/object/entra-id/object-entra-id.svg`,
    'object-entra-id-application': `${icnBase}/object/entra-id/object-azure-entra-id-application.svg`,
    'object-entra-id-admin-unit': `${icnBase}/object/entra-id/object-azure-entra-id-admin-unit.svg`,
    'object-entra-id-contact': `${icnBase}/object/entra-id/object-azure-entra-id-contact.svg`,
    'object-entra-id-directory-role': `${icnBase}/object/entra-id/object-azure-entra-id-directory-role.svg`,
    'object-entra-id-device': `${icnBase}/object/entra-id/object-azure-entra-id-device.svg`,
    'object-entra-id-group': `${icnBase}/object/entra-id/object-azure-entra-id-group.svg`,
    'object-entra-id-service-principal': `${icnBase}/object/entra-id/object-azure-entra-id-service-principal.svg`,
    'object-entra-id-user': `${icnBase}/object/entra-id/object-azure-entra-id-user.svg`,
    'object-entra-id-protected': `${icnBase}/object/entra-id/object-azure-entra-id-protected.svg`,
    'object-entra-id-success': `${icnBase}/object/entra-id/object-entra-id.svg`,
    'object-esxi': `${icnBase}/object/esxi/object-esxi.svg`,
    'object-file-resting': `${icnBase}/core/object-file-resting.svg`,
    'object-folder': `${icnBase}/object/folder/object-folder.svg`,
    'object-folder-auto': `${icnBase}/object/folder/object-folder-auto.svg`,
    'object-folder-partial': `${icnBase}/object/folder/object-folder-partial.svg`,
    'object-folder-protected': `${icnBase}/object/folder/object-folder-protected.svg`,
    'object-gcp-auto': `${icnBase}/object/gcp/object-gcp-auto.svg`,
    'object-gcp-partial': `${icnBase}/object/gcp/object-gcp-partial.svg`,
    'object-gcp-protected': `${icnBase}/object/gcp/object-gcp-protected.svg`,
    'object-gcp': `${icnBase}/object/gcp/object-gcp.svg`,
    'object-generic': `${icnBase}/object/generic/object-generic.svg`,
    'object-hostsystem-auto': `${icnBase}/object/host-system/object-hostsystem-auto.svg`,
    'object-hostsystem-partial': `${icnBase}/object/host-system/object-hostsystem-partial.svg`,
    'object-hostsystem-protected': `${icnBase}/object/host-system/object-hostsystem-protected.svg`,
    'object-hostsystem': `${icnBase}/object/host-system/object-hostsystem.svg`,
    'object-hyperflex': `${icnBase}/object/hyperflex/object-hyperflex.svg`,
    'object-isilon': `${icnBase}/object/isilon/object-isilon.svg`,
    'object-keyspace': `${icnBase}/object/cassandra/object-keyspace.svg`,
    'object-keyspace-auto': `${icnBase}/object/cassandra/object-keyspace-auto.svg`,
    'object-keyspace-cancel': `${icnBase}/object/cassandra/object-keyspace-cancel.svg`,
    'object-keyspace-error': `${icnBase}/object/cassandra/object-keyspace-error.svg`,
    'object-keyspace-partial': `${icnBase}/object/cassandra/object-keyspace-partial.svg`,
    'object-keyspace-protected': `${icnBase}/object/cassandra/object-keyspace-protected.svg`,
    'object-keyspace-success': `${icnBase}/object/cassandra/object-keyspace-success.svg`,
    'object-keyspace-warning': `${icnBase}/object/cassandra/object-keyspace-warning.svg`,
    'object-kubernetes': `${icnBase}/object/kubernetes/object-kubernetes.svg`,
    'object-kubernetes-auto': `${icnBase}/object/kubernetes/object-kubernetes-auto.svg`,
    'object-kubernetes-cancel': `${icnBase}/object/kubernetes/object-kubernetes-cancel.svg`,
    'object-kubernetes-error': `${icnBase}/object/kubernetes/object-kubernetes-error.svg`,
    'object-kubernetes-partial': `${icnBase}/object/kubernetes/object-kubernetes-partial.svg`,
    'object-kubernetes-protected': `${icnBase}/object/kubernetes/object-kubernetes-protected.svg`,
    'object-kubernetes-success': `${icnBase}/object/kubernetes/object-kubernetes-success.svg`,
    'object-kubernetes-warning': `${icnBase}/object/kubernetes/object-kubernetes-warning.svg`,
    'object-kubernetes-running': `${icnBase}/object/kubernetes/object-kubernetes-running.svg`,
    'object-kubernetes-namespace': `${icnBase}/object/kubernetes/object-kubernetes-namespace.svg`,
    'object-pvc': `${icnBase}/object/kubernetes/object-pvc.svg`,
    'object-pvc-protected': `${icnBase}/object/kubernetes/object-pvc-protected.svg`,
    'object-linux-auto': `${icnBase}/object/linux/object-linux-auto.svg`,
    'object-linux-partial': `${icnBase}/object/linux/object-linux-partial.svg`,
    'object-linux-protected': `${icnBase}/object/linux/object-linux-protected.svg`,
    'object-linux': `${icnBase}/object/linux/object-linux.svg`,
    'object-multi-db-auto': `${icnBase}/object/multi-db/object-multi-db-auto.svg`,
    'object-multi-db-partial': `${icnBase}/object/multi-db/object-multi-db-partial.svg`,
    'object-multi-db-protected': `${icnBase}/object/multi-db/object-multi-db-protected.svg`,
    'object-multi-db': `${icnBase}/object/multi-db/object-multi-db.svg`,
    'object-mongodb-cluster': `${icnBase}/object/mongodb/object-mongodb-cluster.svg`,
    'object-mongodb-cluster-auto': `${icnBase}/object/mongodb/object-mongodb-cluster-auto.svg`,
    'object-mongodb-cluster-cancel': `${icnBase}/object/mongodb/object-mongodb-cluster-cancel.svg`,
    'object-mongodb-cluster-error': `${icnBase}/object/mongodb/object-mongodb-cluster-error.svg`,
    'object-mongodb-cluster-partial': `${icnBase}/object/mongodb/object-mongodb-cluster-partial.svg`,
    'object-mongodb-cluster-protected': `${icnBase}/object/mongodb/object-mongodb-cluster-protected.svg`,
    'object-mongodb-cluster-running': `${icnBase}/object/mongodb/object-mongodb-cluster-running.svg`,
    'object-mongodb-cluster-success': `${icnBase}/object/mongodb/object-mongodb-cluster-success.svg`,
    'object-mongodb-cluster-warning': `${icnBase}/object/mongodb/object-mongodb-cluster-warning.svg`,
    'object-mongodb-ops-manager': `${icnBase}/object/mongodb/object-mongodb-ops-manager.svg`,
    'object-mongodb-ops-manager-auto': `${icnBase}/object/mongodb/object-mongodb-ops-manager-auto.svg`,
    'object-mongodb-ops-manager-cancel': `${icnBase}/object/mongodb/object-mongodb-ops-manager-cancel.svg`,
    'object-mongodb-ops-manager-partial': `${icnBase}/object/mongodb/object-mongodb-ops-manager-partial.svg`,
    'object-mongodb-ops-manager-error': `${icnBase}/object/mongodb/object-mongodb-ops-manager-error.svg`,
    'object-mongodb-ops-manager-protected': `${icnBase}/object/mongodb/object-mongodb-ops-manager-protected.svg`,
    'object-mongodb-ops-manager-running': `${icnBase}/object/mongodb/object-mongodb-ops-manager-running.svg`,
    'object-mongodb-ops-manager-success': `${icnBase}/object/mongodb/object-mongodb-ops-manager-success.svg`,
    'object-mongodb-ops-manager-warning': `${icnBase}/object/mongodb/object-mongodb-ops-manager-warning.svg`,
    'object-mongodb-organization': `${icnBase}/object/mongodb/object-mongodb-organization.svg`,
    'object-mongodb-organization-auto': `${icnBase}/object/mongodb/object-mongodb-organization-auto.svg`,
    'object-mongodb-organization-partial': `${icnBase}/object/mongodb/object-mongodb-organization-partial.svg`,
    'object-mongodb-organization-protected': `${icnBase}/object/mongodb/object-mongodb-organization-protected.svg`,
    'object-mongodb-project': `${icnBase}/object/mongodb/object-mongodb-project.svg`,
    'object-mongodb-project-auto': `${icnBase}/object/mongodb/object-mongodb-project-auto.svg`,
    'object-mongodb-project-cancel': `${icnBase}/object/mongodb/object-mongodb-project-cancel.svg`,
    'object-mongodb-project-error': `${icnBase}/object/mongodb/object-mongodb-project-error.svg`,
    'object-mongodb-project-partial': `${icnBase}/object/mongodb/object-mongodb-project-partial.svg`,
    'object-mongodb-project-protected': `${icnBase}/object/mongodb/object-mongodb-project-protected.svg`,
    'object-mongodb-project-running': `${icnBase}/object/mongodb/object-mongodb-project-running.svg`,
    'object-mongodb-project-success': `${icnBase}/object/mongodb/object-mongodb-project-success.svg`,
    'object-mongodb-project-warning': `${icnBase}/object/mongodb/object-mongodb-project-warning.svg`,
    'object-o365': `${icnBase}/object/o365/object-o365.svg`,
    'object-o365-calendar': `${icnBase}/object/o365-calendar/object-o365-calendar.svg`,
    'object-o365-calendar-auto': `${icnBase}/object/o365-calendar/object-o365-calendar-auto.svg`,
    'object-o365-calendar-partial': `${icnBase}/object/o365-calendar/object-o365-calendar-partial.svg`,
    'object-o365-calendar-protected': `${icnBase}/object/o365-calendar/object-o365-calendar-protected.svg`,
    'object-o365-calendar-skip': `${icnBase}/object/o365-calendar/object-o365-calendar-skip.svg`,
    'object-o365-calendar-success': `${icnBase}/object/o365-calendar/object-o365-calendar-success.svg`,
    'object-o365-contact': `${icnBase}/object/o365-contact/object-o365-contact.svg`,
    'object-o365-contact-auto': `${icnBase}/object/o365-contact/object-o365-contact-auto.svg`,
    'object-o365-contact-partial': `${icnBase}/object/o365-contact/object-o365-contact-partial.svg`,
    'object-o365-contact-protected': `${icnBase}/object/o365-contact/object-o365-contact-protected.svg`,
    'object-o365-contact-skip': `${icnBase}/object/o365-contact/object-o365-contact-skip.svg`,
    'object-o365-contact-success': `${icnBase}/object/o365-contact/object-o365-contact-success.svg`,
    'object-o365-mailbox': `${icnBase}/object/o365-mailbox/object-o365-mailbox.svg`,
    'object-o365-mailbox-auto': `${icnBase}/object/o365-mailbox/object-o365-mailbox-auto.svg`,
    'object-o365-mailbox-cancel': `${icnBase}/object/o365-mailbox/object-o365-mailbox-cancel.svg`,
    'object-o365-mailbox-error': `${icnBase}/object/o365-mailbox/object-o365-mailbox-error.svg`,
    'object-o365-mailbox-partial': `${icnBase}/object/o365-mailbox/object-o365-mailbox-partial.svg`,
    'object-o365-mailbox-protected': `${icnBase}/object/o365-mailbox/object-o365-mailbox-protected.svg`,
    'object-o365-mailbox-running': `${icnBase}/object/o365-mailbox/object-o365-mailbox-running.svg`,
    'object-o365-mailbox-skip': `${icnBase}/object/o365-mailbox/object-o365-mailbox-skip.svg`,
    'object-o365-mailbox-success': `${icnBase}/object/o365-mailbox/object-o365-mailbox-success.svg`,
    'object-o365-mailbox-warn': `${icnBase}/object/o365-mailbox/object-o365-mailbox-warn.svg`,
    'object-o365-msgroup': `${icnBase}/object/o365-msgroup/object-o365-msgroup.svg`,
    'object-o365-msgroup-auto': `${icnBase}/object/o365-msgroup/object-o365-msgroup-auto.svg`,
    'object-o365-msgroup-cancel': `${icnBase}/object/o365-msgroup/object-o365-msgroup-cancel.svg`,
    'object-o365-msgroup-disabled': `${icnBase}/object/o365-msgroup/object-o365-msgroup-disabled.svg`,
    'object-o365-msgroup-error': `${icnBase}/object/o365-msgroup/object-o365-msgroup-error.svg`,
    'object-o365-msgroup-partial': `${icnBase}/object/o365-msgroup/object-o365-msgroup-partial.svg`,
    'object-o365-msgroup-protected': `${icnBase}/object/o365-msgroup/object-o365-msgroup-protected.svg`,
    'object-o365-msgroup-skip': `${icnBase}/object/o365-msgroup/object-o365-msgroup-skip.svg`,
    'object-o365-msgroup-success': `${icnBase}/object/o365-msgroup/object-o365-msgroup-success.svg`,
    'object-o365-msgroup-warning': `${icnBase}/object/o365-msgroup/object-o365-msgroup-warning.svg`,
    'object-o365-msteam': `${icnBase}/object/o365-msteam/object-o365-msteam.svg`,
    'object-o365-msteam-auto': `${icnBase}/object/o365-msteam/object-o365-msteam-auto.svg`,
    'object-o365-msteam-partial': `${icnBase}/object/o365-msteam/object-o365-msteam-partial.svg`,
    'object-o365-msteam-protected': `${icnBase}/object/o365-msteam/object-o365-msteam-protected.svg`,
    'object-o365-msteam-skip': `${icnBase}/object/o365-msteam/object-o365-msteam-skip.svg`,
    'object-o365-msteam-success': `${icnBase}/object/o365-msteam/object-o365-msteam-success.svg`,
    'object-o365-msteam-error': `${icnBase}/object/o365-msteam/object-o365-msteam-error.svg`,
    'object-o365-msteamchannel': `${icnBase}/object/o365-msteam/object-o365-msteamchannel.svg`,
    'object-o365-msteampost': `${icnBase}/object/o365-msteam/object-o365-msteampost.svg`,
    'object-o365-note': `${icnBase}/object/o365-note/object-o365-note.svg`,
    'object-o365-note-auto': `${icnBase}/object/o365-note/object-o365-note-auto.svg`,
    'object-o365-note-partial': `${icnBase}/object/o365-note/object-o365-note-partial.svg`,
    'object-o365-note-protected': `${icnBase}/object/o365-note/object-o365-note-protected.svg`,
    'object-o365-note-skip': `${icnBase}/object/o365-note/object-o365-note-skip.svg`,
    'object-o365-note-success': `${icnBase}/object/o365-note/object-o365-note-success.svg`,
    'object-o365-onedrive': `${icnBase}/object/o365-onedrive/object-o365-onedrive.svg`,
    'object-o365-onedrive-auto': `${icnBase}/object/o365-onedrive/object-o365-onedrive-auto.svg`,
    'object-o365-onedrive-cancel': `${icnBase}/object/o365-onedrive/object-o365-onedrive-cancel.svg`,
    'object-o365-onedrive-error': `${icnBase}/object/o365-onedrive/object-o365-onedrive-error.svg`,
    'object-o365-onedrive-partial': `${icnBase}/object/o365-onedrive/object-o365-onedrive-partial.svg`,
    'object-o365-onedrive-protected': `${icnBase}/object/o365-onedrive/object-o365-onedrive-protected.svg`,
    'object-o365-onedrive-running': `${icnBase}/object/o365-onedrive/object-o365-onedrive-running.svg`,
    'object-o365-onedrive-skip': `${icnBase}/object/o365-onedrive/object-o365-onedrive-skip.svg`,
    'object-o365-onedrive-success': `${icnBase}/object/o365-onedrive/object-o365-onedrive-success.svg`,
    'object-o365-onedrive-warn': `${icnBase}/object/o365-onedrive/object-o365-onedrive-warning.svg`,
    'object-o365-privatechat': `${icnBase}/object/o365-mailbox/object-o365-privatechat.svg`,
    'object-o365-publicfolder': `${icnBase}/object/o365-publicfolder/object-o365-publicfolder.svg`,
    'object-o365-publicfolder-cancel': `${icnBase}/object/o365-publicfolder/object-o365-publicfolder-cancel.svg`,
    'object-o365-publicfolder-disabled': `${icnBase}/object/o365-publicfolder/object-o365-publicfolder-disabled.svg`,
    'object-o365-publicfolder-auto': `${icnBase}/object/o365-publicfolder/object-o365-publicfolder-auto.svg`,
    'object-o365-publicfolder-partial': `${icnBase}/object/o365-publicfolder/object-o365-publicfolder-partial.svg`,
    'object-o365-publicfolder-protected': `${icnBase}/object/o365-publicfolder/object-o365-publicfolder-protected.svg`,
    'object-o365-publicfolder-success': `${icnBase}/object/o365-publicfolder/object-o365-publicfolder-success.svg`,
    'object-o365-publicfolder-warning': `${icnBase}/object/o365-publicfolder/object-o365-publicfolder-warning.svg`,
    'object-o365-publicfolder-error': `${icnBase}/object/o365-publicfolder/object-o365-publicfolder-error.svg`,
    'object-o365-security-group': `${icnBase}/object/o365-security-group/object-o365-security-group.svg`,
    'object-o365-security-group-cancel': `${icnBase}/object/o365-security-group/object-o365-security-group-cancel.svg`,
    'object-o365-security-group-disabled': `${icnBase}/object/o365-security-group/object-o365-security-group-disabled.svg`,
    'object-o365-security-group-auto': `${icnBase}/object/o365-security-group/object-o365-security-group-auto.svg`,
    'object-o365-security-group-partial': `${icnBase}/object/o365-security-group/object-o365-security-group-partial.svg`,
    'object-o365-security-group-protected': `${icnBase}/object/o365-security-group/object-o365-security-group-protected.svg`,
    'object-o365-security-group-success': `${icnBase}/object/o365-security-group/object-o365-security-group-success.svg`,
    'object-o365-security-group-warning': `${icnBase}/object/o365-security-group/object-o365-security-group-warning.svg`,
    'object-o365-security-group-error': `${icnBase}/object/o365-security-group/object-o365-security-group-error.svg`,
    'object-o365-sites': `${icnBase}/object/o365-sites/object-o365-sites.svg`,
    'object-o365-sites-auto': `${icnBase}/object/o365-sites/object-o365-sites-auto.svg`,
    'object-o365-sites-partial': `${icnBase}/object/o365-sites/object-o365-sites-partial.svg`,
    'object-o365-sites-protected': `${icnBase}/object/o365-sites/object-o365-sites-protected.svg`,
    'object-o365-sites-skip': `${icnBase}/object/o365-sites/object-o365-sites-skip.svg`,
    'object-o365-sites-success': `${icnBase}/object/o365-sites/object-o365-sites-success.svg`,
    'object-o365-task': `${icnBase}/object/o365-task/object-o365-task.svg`,
    'object-o365-task-auto': `${icnBase}/object/o365-task/object-o365-task-auto.svg`,
    'object-o365-task-partial': `${icnBase}/object/o365-task/object-o365-task-partial.svg`,
    'object-o365-task-protected': `${icnBase}/object/o365-task/object-o365-task-protected.svg`,
    'object-o365-task-skip': `${icnBase}/object/o365-task/object-o365-task-skip.svg`,
    'object-o365-task-success': `${icnBase}/object/o365-task/object-o365-task-success.svg`,
    'object-oracle': `${icnBase}/object/oracle/object-oracle.svg`,
    'object-oracle-rac-auto': `${icnBase}/object/oracle-rac/object-oracle-rac-auto.svg`,
    'object-oracle-rac-partial': `${icnBase}/object/oracle-rac/object-oracle-rac-partial.svg`,
    'object-oracle-rac-protected': `${icnBase}/object/oracle-rac/object-oracle-rac-protected.svg`,
    'object-oracle-rac': `${icnBase}/object/oracle-rac/object-oracle-rac.svg`,
    'object-organization-auto': `${icnBase}/object/organization/object-organization-auto.svg`,
    'object-organization-partial': `${icnBase}/object/organization/object-organization-partial.svg`,
    'object-organization-protected': `${icnBase}/object/organization/object-organization-protected.svg`,
    'object-organization': `${icnBase}/object/organization/object-organization.svg`,
    'object-helios-organization': `${icnBase}/object/organization/object-helios-organization.svg`,
    'object-table-view': `${icnBase}/object/hive/object-table-view.svg`,
    'object-table-view-auto': `${icnBase}/object/hive/object-table-view-auto.svg`,
    'object-table-view-cancel': `${icnBase}/object/hive/object-table-view-cancel.svg`,
    'object-table-view-error': `${icnBase}/object/hive/object-table-view-error.svg`,
    'object-table-view-partial': `${icnBase}/object/hive/object-table-view-partial.svg`,
    'object-table-view-protected': `${icnBase}/object/hive/object-table-view-protected.svg`,
    'object-table-view-success': `${icnBase}/object/hive/object-table-view-success.svg`,
    'object-table-view-warning': `${icnBase}/object/hive/object-table-view-warning.svg`,
    'object-hpux': `${icnBase}/object/hpux/object-hpux.svg`,
    'object-project-auto': `${icnBase}/object/project/object-project-auto.svg`,
    'object-project-partial': `${icnBase}/object/project/object-project-partial.svg`,
    'object-project-protected': `${icnBase}/object/project/object-project-protected.svg`,
    'object-project': `${icnBase}/object/project/object-project.svg`,
    'object-ibmflashsystem-protection-group': `${icnBase}/object/ibmflashsystem/object-ibmflashsystem-protection-group.svg`,
    'object-ibmflashsystem-protection-group-auto': `${icnBase}/object/ibmflashsystem/object-ibmflashsystem-protection-group-auto.svg`,
    'object-ibmflashsystem-protection-group-cancel': `${icnBase}/object/ibmflashsystem/object-ibmflashsystem-protection-group-cancel.svg`,
    'object-ibmflashsystem-protection-group-error': `${icnBase}/object/ibmflashsystem/object-ibmflashsystem-protection-group-error.svg`,
    'object-ibmflashsystem-protection-group-partial': `${icnBase}/object/ibmflashsystem/object-ibmflashsystem-protection-group-partial.svg`,
    'object-ibmflashsystem-protection-group-protected': `${icnBase}/object/ibmflashsystem/object-ibmflashsystem-protection-group-protected.svg`,
    'object-ibmflashsystem-protection-group-running': `${icnBase}/object/ibmflashsystem/object-ibmflashsystem-protection-group-running.svg`,
    'object-ibmflashsystem-protection-group-success': `${icnBase}/object/ibmflashsystem/object-ibmflashsystem-protection-group-success.svg`,
    'object-ibmflashsystem-protection-group-warning': `${icnBase}/object/ibmflashsystem/object-ibmflashsystem-protection-group-warning.svg`,
    'object-pure-protection-group': `${icnBase}/object/pure/object-pure-protection-group.svg`,
    'object-pure-protection-group-auto': `${icnBase}/object/pure/object-pure-protection-group-auto.svg`,
    'object-pure-protection-group-cancel': `${icnBase}/object/pure/object-pure-protection-group-cancel.svg`,
    'object-pure-protection-group-error': `${icnBase}/object/pure/object-pure-protection-group-error.svg`,
    'object-pure-protection-group-partial': `${icnBase}/object/pure/object-pure-protection-group-partial.svg`,
    'object-pure-protection-group-protected': `${icnBase}/object/pure/object-pure-protection-group-protected.svg`,
    'object-pure-protection-group-running': `${icnBase}/object/pure/object-pure-protection-group-running.svg`,
    'object-pure-protection-group-success': `${icnBase}/object/pure/object-pure-protection-group-success.svg`,
    'object-pure-protection-group-warning': `${icnBase}/object/pure/object-pure-protection-group-warning.svg`,
    'object-region-auto': `${icnBase}/object/region/object-region-auto.svg`,
    'object-region-partial': `${icnBase}/object/region/object-region-partial.svg`,
    'object-region-protected': `${icnBase}/object/region/object-region-protected.svg`,
    'object-region': `${icnBase}/object/region/object-region.svg`,
    'object-report-success': `${icnBase}/object/report/object-report-success.svg`,
    'object-resource-pool-auto': `${icnBase}/object/resource-pool/object-resource-pool-auto.svg`,
    'object-resource-pool-error': `${icnBase}/object/resource-pool/object-resource-pool-error.svg`,
    'object-resource-pool-partial': `${icnBase}/object/resource-pool/object-resource-pool-partial.svg`,
    'object-resource-pool-protected': `${icnBase}/object/resource-pool/object-resource-pool-protected.svg`,
    'object-resource-pool-success': `${icnBase}/object/resource-pool/object-resource-pool-success.svg`,
    'object-resource-pool-warn': `${icnBase}/object/resource-pool/object-resource-pool-warn.svg`,
    'object-resource-pool': `${icnBase}/object/resource-pool/object-resource-pool.svg`,
    'object-server': `${icnBase}/object/server/object-server.svg`,
    'object-server-auto': `${icnBase}/object/server/object-server-auto.svg`,
    'object-server-partial': `${icnBase}/object/server/object-server-partial.svg`,
    'object-server-protected': `${icnBase}/object/server/object-server-protected.svg`,
    'object-server-skip': `${icnBase}/object/server/object-server-skip.svg`,
    'object-server-success': `${icnBase}/object/server/object-server-success.svg`,
    'object-salesforce': `${icnBase}/object/salesforce/object-salesforce.svg`,
    'object-salesforce-skip': `${icnBase}/object/salesforce/object-salesforce-skip.svg`,
    'object-salesforce-success': `${icnBase}/object/salesforce/object-salesforce-success.svg`,
    'object-salesforce-auto': `${icnBase}/object/salesforce/object-salesforce-auto.svg`,
    'object-salesforce-cancel': `${icnBase}/object/salesforce/object-salesforce-cancel.svg`,
    'object-salesforce-error': `${icnBase}/object/salesforce/object-salesforce-error.svg`,
    'object-salesforce-partial': `${icnBase}/object/salesforce/object-salesforce-partial.svg`,
    'object-salesforce-protected': `${icnBase}/object/salesforce/object-salesforce-protected.svg`,
    'object-salesforce-warning': `${icnBase}/object/salesforce/object-salesforce-warning.svg`,
    'object-solaris-auto': `${icnBase}/object/solaris/object-solaris-auto.svg`,
    'object-solaris-partial': `${icnBase}/object/solaris/object-solaris-partial.svg`,
    'object-solaris-protected': `${icnBase}/object/solaris/object-solaris-protected.svg`,
    'object-solaris': `${icnBase}/object/solaris/object-solaris.svg`,
    'object-storage-domain': `${icnBase}/object/storage-domain/object-storage-domain.svg`,
    'object-system-keyspace': `${icnBase}/object/cassandra/object-system-keyspace.svg`,
    'object-system-keyspace-auto': `${icnBase}/object/cassandra/object-system-keyspace-auto.svg`,
    'object-system-keyspace-cancel': `${icnBase}/object/cassandra/object-system-keyspace-cancel.svg`,
    'object-system-keyspace-error': `${icnBase}/object/cassandra/object-system-keyspace-error.svg`,
    'object-system-keyspace-partial': `${icnBase}/object/cassandra/object-system-keyspace-partial.svg`,
    'object-system-keyspace-protected': `${icnBase}/object/cassandra/object-system-keyspace-protected.svg`,
    'object-system-keyspace-success': `${icnBase}/object/cassandra/object-system-keyspace-success.svg`,
    'object-system-keyspace-warning': `${icnBase}/object/cassandra/object-system-keyspace-warning.svg`,
    'object-table-auto': `${icnBase}/object/table/object-table-auto.svg`,
    'object-table-partial': `${icnBase}/object/table/object-table-partial.svg`,
    'object-table-protected': `${icnBase}/object/table/object-table-protected.svg`,
    'object-table': `${icnBase}/object/table/object-table.svg`,
    'object-tag-auto': `${icnBase}/object/tag/object-tag-auto.svg`,
    'object-tag-partial': `${icnBase}/object/tag/object-tag-partial.svg`,
    'object-tag-protected': `${icnBase}/object/tag/object-tag-protected.svg`,
    'object-tag': `${icnBase}/object/tag/object-tag.svg`,
    'object-user': `${icnBase}/object/user/object-user.svg`,
    'object-user-auto': `${icnBase}/object/user/object-user-auto.svg`,
    'object-user-partial': `${icnBase}/object/user/object-user-partial.svg`,
    'object-user-protected': `${icnBase}/object/user/object-user-protected.svg`,
    'object-user-skip': `${icnBase}/object/user/object-user-skip.svg`,
    'object-user-success': `${icnBase}/object/user/object-user-success.svg`,
    'object-user-group': `${icnBase}/object/user-group/object-user-group.svg`,
    'object-user-supervisor': `${icnBase}/object/user-supervisor/object-user-supervisor.svg`,
    'object-vapp-template-auto': `${icnBase}/object/vapp-template/object-vapp-template-auto.svg`,
    'object-vapp-template-partial': `${icnBase}/object/vapp-template/object-vapp-template-partial.svg`,
    'object-vapp-template-protected': `${icnBase}/object/vapp-template/object-vapp-template-protected.svg`,
    'object-vapp-template': `${icnBase}/object/vapp-template/object-vapp-template.svg`,
    'object-vcloud-dir-auto': `${icnBase}/object/vcloud-dir/object-vcloud-dir-auto.svg`,
    'object-vcloud-dir-partial': `${icnBase}/object/vcloud-dir/object-vcloud-dir-partial.svg`,
    'object-vcloud-dir-protected': `${icnBase}/object/vcloud-dir/object-vcloud-dir-protected.svg`,
    'object-vcloud-dir': `${icnBase}/object/vcloud-dir/object-vcloud-dir.svg`,
    'object-vcloud-vapp-auto': `${icnBase}/object/vcloud-vapp/object-vcloud-vapp-auto.svg`,
    'object-vcloud-vapp-partial': `${icnBase}/object/vcloud-vapp/object-vcloud-vapp-partial.svg`,
    'object-vcloud-vapp-protected': `${icnBase}/object/vcloud-vapp/object-vcloud-vapp-protected.svg`,
    'object-vcloud-vapp': `${icnBase}/object/vcloud-vapp/object-vcloud-vapp.svg`,
    'object-view-auto': `${icnBase}/object/view/object-view-auto.svg`,
    'object-view-clone': `${icnBase}/object/view/object-view-clone.svg`,
    'object-view-completed': `${icnBase}/object/view/view-completed.svg`,
    'object-view-in-progress': `${icnBase}/object/view/view-in-progress.svg`,
    'object-view-partial': `${icnBase}/object/view/object-view-partial.svg`,
    'object-view-paused': `${icnBase}/object/view/view-paused.svg`,
    'object-view-protected': `${icnBase}/object/view/object-view-protected.svg`,
    'object-view-queued': `${icnBase}/object/view/view-queued.svg`,
    'object-view-ready': `${icnBase}/object/view/view-ready.svg`,
    'object-view': `${icnBase}/object/view/object-view.svg`,
    'object-vm-aix-auto': `${icnBase}/object/vm-aix/object-vm-aix-auto.svg`,
    'object-vm-aix-partial': `${icnBase}/object/vm-aix/object-vm-aix-partial.svg`,
    'object-vm-aix-protected': `${icnBase}/object/vm-aix/object-vm-aix-protected.svg`,
    'object-vm-aix': `${icnBase}/object/vm-aix/object-vm-aix.svg`,
    'object-vm-app-auto': `${icnBase}/object/vm-app/object-vm-app-auto.svg`,
    'object-vm-app-partial': `${icnBase}/object/vm-app/object-vm-app-partial.svg`,
    'object-vm-app-protected': `${icnBase}/object/vm-app/object-vm-app-protected.svg`,
    'object-vm-app': `${icnBase}/object/vm-app/object-vm-app.svg`,
    'object-vm-auto': `${icnBase}/object/vm/object-vm-auto.svg`,
    'object-vm-clone': `${icnBase}/object/vm/object-vm-clone.svg`,
    'object-vm-hyperv-auto': `${icnBase}/object/vm-hyperv/object-vm-hyperv-auto.svg`,
    'object-vm-hyperv-partial': `${icnBase}/object/vm-hyperv/object-vm-hyperv-partial.svg`,
    'object-vm-hyperv-protected': `${icnBase}/object/vm-hyperv/object-vm-hyperv-protected.svg`,
    'object-vm-hyperv': `${icnBase}/object/vm-hyperv/object-vm-hyperv.svg`,
    'object-vm-linux-auto': `${icnBase}/object/vm-linux/object-vm-linux-auto.svg`,
    'object-vm-linux-partial': `${icnBase}/object/vm-linux/object-vm-linux-partial.svg`,
    'object-vm-linux-protected': `${icnBase}/object/vm-linux/object-vm-linux-protected.svg`,
    'object-vm-linux': `${icnBase}/object/vm-linux/object-vm-linux.svg`,
    'object-vm-partial': `${icnBase}/object/vm/object-vm-partial.svg`,
    'object-vm-protected': `${icnBase}/object/vm/object-vm-protected.svg`,
    'object-vm-skip': `${icnBase}/object/vm/object-vm-skip.svg`,
    'object-vm-solaris-auto': `${icnBase}/object/vm-solaris/object-vm-solaris-auto.svg`,
    'object-vm-solaris-partial': `${icnBase}/object/vm-solaris/object-vm-solaris-partial.svg`,
    'object-vm-solaris-protected': `${icnBase}/object/vm-solaris/object-vm-solaris-protected.svg`,
    'object-vm-solaris': `${icnBase}/object/vm-solaris/object-vm-solaris.svg`,
    'object-vm-template-auto': `${icnBase}/object/vm-template/object-vm-template-auto.svg`,
    'object-vm-template-partial': `${icnBase}/object/vm-template/object-vm-template-partial.svg`,
    'object-vm-template-protected': `${icnBase}/object/vm-template/object-vm-template-protected.svg`,
    'object-vm-template': `${icnBase}/object/vm-template/object-vm-template.svg`,
    'object-vm-windows-auto': `${icnBase}/object/vm-windows/object-vm-windows-auto.svg`,
    'object-vm-windows-partial': `${icnBase}/object/vm-windows/object-vm-windows-partial.svg`,
    'object-vm-windows-protected': `${icnBase}/object/vm-windows/object-vm-windows-protected.svg`,
    'object-vm-windows': `${icnBase}/object/vm-windows/object-vm-windows.svg`,
    'object-vm': `${icnBase}/object/vm/object-vm.svg`,
    'object-vmtools-auto': `${icnBase}/object/vmtools/object-vmtools-auto.svg`,
    'object-vmtools-partial': `${icnBase}/object/vmtools/object-vmtools-partial.svg`,
    'object-vmtools-protected': `${icnBase}/object/vmtools/object-vmtools-protected.svg`,
    'object-vmtools': `${icnBase}/object/vmtools/object-vmtools.svg`,
    'object-vmware-esx-host-auto': `${icnBase}/object/vmware-esx-host/object-vmware-esx-host-auto.svg`,
    'object-vmware-esx-host-partial': `${icnBase}/object/vmware-esx-host/object-vmware-esx-host-partial.svg`,
    'object-vmware-esx-host-protected': `${icnBase}/object/vmware-esx-host/object-vmware-esx-host-protected.svg`,
    'object-vmware-esx-host': `${icnBase}/object/vmware-esx-host/object-vmware-esx-host.svg`,
    'object-vmware-vcenter-auto': `${icnBase}/object/vmware-vcenter/object-vmware-vcenter-auto.svg`,
    'object-vmware-vcenter-partial': `${icnBase}/object/vmware-vcenter/object-vmware-vcenter-partial.svg`,
    'object-vmware-vcenter-protected': `${icnBase}/object/vmware-vcenter/object-vmware-vcenter-protected.svg`,
    'object-vmware-vcenter-success': `${icnBase}/object/vmware-vcenter/object-vmware-vcenter-success.svg`,
    'object-vmware-vcenter': `${icnBase}/object/vmware-vcenter/object-vmware-vcenter.svg`,
    'object-volume-auto': `${icnBase}/object/volume/object-volume-auto.svg`,
    'object-volume-partial': `${icnBase}/object/volume/object-volume-partial.svg`,
    'object-volume-protected': `${icnBase}/object/volume/object-volume-protected.svg`,
    'object-volume-skip': `${icnBase}/object/volume/object-volume-skip.svg`,
    'object-volume-cancel': `${icnBase}/object/volume/object-volume-cancel.svg`,
    'object-volume-error': `${icnBase}/object/volume/object-volume-error.svg`,
    'object-volume-running': `${icnBase}/object/volume/object-volume-running.svg`,
    'object-volume-success': `${icnBase}/object/volume/object-volume-success.svg`,
    'object-volume-warning': `${icnBase}/object/volume/object-volume-warning.svg`,
    'object-volume': `${icnBase}/object/volume/object-volume.svg`,
    'object-windows-auto': `${icnBase}/object/windows/object-windows-auto.svg`,
    'object-windows-partial': `${icnBase}/object/windows/object-windows-partial.svg`,
    'object-windows-protected': `${icnBase}/object/windows/object-windows-protected.svg`,
    'object-windows': `${icnBase}/object/windows/object-windows.svg`,

    // Status icons
    'status-cancel-alt': `${icnBase}/status/status-cancel-alt.svg`,
    'status-cancel': `${icnBase}/status/status-cancel.svg`,
    'status-delete': `${icnBase}/status/status-delete.svg`,
    'status-error-alt': `${icnBase}/status/status-error-alt.svg`,
    'status-error': `${icnBase}/status/status-error.svg`,
    'status-in-progress-alt': `${icnBase}/status/status-in-progress-alt.svg`,
    'status-in-progress': `${icnBase}/status/status-in-progress.svg`,
    'status-info-alt': `${icnBase}/status/status-info-alt.svg`,
    'status-info': `${icnBase}/status/status-info.svg`,
    'status-protect-auto': `${icnBase}/status/status-protect-auto.svg`,
    'status-protect-partial': `${icnBase}/status/status-protect-partial.svg`,
    'status-protect-solid': `${icnBase}/status/status-protect-solid.svg`,
    'status-protect': `${icnBase}/status/status-protect.svg`,
    'status-queue': `${icnBase}/status/status-queue.svg`,
    'status-queue-alt': `${icnBase}/status/status-queue-alt.svg`,
    'status-scheduled-alt': `${icnBase}/status/status-scheduled-alt.svg`,
    'status-shield-outline': `${icnBase}/status/status-shield-outline.svg`,
    'status-shield-solid': `${icnBase}/status/status-shield-solid.svg`,
    'status-skip-alt': `${icnBase}/status/status-skip-alt.svg`,
    'status-skip': `${icnBase}/status/status-skip.svg`,
    'status-success-alt': `${icnBase}/status/status-success-alt.svg`,
    'status-success-thin': `${icnBase}/status/status-success-thin.svg`,
    'status-success': `${icnBase}/status/status-success.svg`,
    'status-unprotected-outline': `${icnBase}/status/status-unprotected-outline.svg`,
    'status-unprotected': `${icnBase}/status/status-unprotected.svg`,
    'status-warning-alt': `${icnBase}/status/status-warning-alt.svg`,
    'status-warning': `${icnBase}/status/status-warning.svg`,
    'status-source-scheduled-maintenance': `${icnBase}/status/status-source-scheduled-maintenance.svg`,
    'status-source-under-maintenance': `${icnBase}/status/status-source-under-maintenance.svg`,

    // app icons
    'app-argus': `${icnBase}/app/argus.svg`,
    'app-cluster-manager': `${icnBase}/app/cluster-manager.svg`,
    'app-ransomware': `${icnBase}/app/ransomware.svg`,

    // logo icons
    'logo-cluster-manager': `${icnBase}/logos/cluster-manager.svg`,
    'logo-data-govern': `${icnBase}/logos/data-govern.svg`,
    'logo-data-protect': `${icnBase}/logos/data-protect.svg`,
    'logo-fort-knox': `${icnBase}/logos/fort-knox.svg`,
    'logo-global': `${icnBase}/logos/global.svg`,
    'logo-security-center': `${icnBase}/logos/security-center.svg`,
    'logo-sheltered-harbor':`${icnBase}/logos/sheltered-harbor.svg`,
    'logo-site-continuity': `${icnBase}/logos/site-continuity.svg`,
    'logo-appliance-manager': `${icnBase}/logos/appliance-manager.svg`,
    'logo-vault-green': `${icnBase}/logos/vault-green.svg`,
    'logo-vault-data-protect': `${icnBase}/logos/vault-data-protect.svg`,

    // 5 pillars for apps.
    'pillar-access': `${icnBase}/logos/pillar-access-line.svg`,
    'pillar-global': `${icnBase}/logos/pillar-global-line.svg`,
    'pillar-insights': `${icnBase}/logos/pillar-insights-line.svg`,
    'pillar-mobility': `${icnBase}/logos/pillar-mobility-line.svg`,
    'pillar-protection': `${icnBase}/logos/pillar-protection-line.svg`,
    'pillar-security': `${icnBase}/logos/pillar-security-line.svg`,
    'pillar-access-solid': `${icnBase}/logos/pillar-access-solid.svg`,
    'pillar-insights-solid': `${icnBase}/logos/pillar-insights-solid.svg`,
    'pillar-mobility-solid': `${icnBase}/logos/pillar-mobility-solid.svg`,
    'pillar-protection-solid': `${icnBase}/logos/pillar-protection-solid.svg`,
    'pillar-security-solid': `${icnBase}/logos/pillar-security-solid.svg`,
    'pillar-access-lg': `${icnBase}/logos/pillar-access-line-lg.svg`,
    'pillar-insights-lg': `${icnBase}/logos/pillar-insights-line-lg.svg`,
    'pillar-mobility-lg': `${icnBase}/logos/pillar-mobility-line-lg.svg`,
    'pillar-protection-lg': `${icnBase}/logos/pillar-protection-line-lg.svg`,
    'pillar-security-lg': `${icnBase}/logos/pillar-security-line-lg.svg`,

    // Same icon as above line icons but with different defs ids to avoid missing id (style problem)
    // when page changed from showing 1 instead 2 of the same icon on the same page.
    'pillar2-access': `${icnBase}/logos/pillar2-access-line.svg`,
    'pillar2-global': `${icnBase}/logos/pillar2-global-line.svg`,
    'pillar2-insights': `${icnBase}/logos/pillar2-insights-line.svg`,
    'pillar2-mobility': `${icnBase}/logos/pillar2-mobility-line.svg`,
    'pillar2-protection': `${icnBase}/logos/pillar2-protection-line.svg`,
    'pillar2-security': `${icnBase}/logos/pillar2-security-line.svg`,
    'pillar2-appliance-manager': `${icnBase}/logos/appliance-manager.svg`,

    'physical-server': `${icnBase}/core/helix-physical-server.svg`,
    'physical-server-success': `${icnBase}/core/helix-physical-server-success.svg`,

    // blank card bg images
    'no-data-cluster-cohesity': `${icnBase}/core/cluster-cohesity.svg`,
    'no-data-empty-pad': `${icnBase}/core/empty-pad.svg`,
    'no-data-img-anomalies': `${icnBase}/core/no-data-anomalies.svg`,
    'no-data-img-blank-dashboard': `${icnBase}/core/blank-dashboard.svg`,
    'no-data-img-general': `i/no-data-general.svg`,
    'no-data-img-no-activity-found': `${icnBase}/core/no-activity-found.svg`,
    'no-data-img-threats': `${icnBase}/core/no-data-threats.svg`,
    'no-draggable-item': `${icnBase}/core/no-draggable-item.svg`,
    'no-files-submitted': `${icnBase}/core/no-files-submitted.svg`,

    // security score verdict icons
    'verdict-critical': `${icnBase}/core/security-center/verdict-critical.svg`,
    'verdict-high': `${icnBase}/core/security-center/verdict-high.svg`,
    'verdict-medium': `${icnBase}/core/security-center/verdict-medium.svg`,
    'verdict-low': `${icnBase}/core/security-center/verdict-low.svg`,
    'verdict-okay': `${icnBase}/core/security-center/verdict-okay.svg`,

    // actions
    'action-tag': `${icnBase}/core/action-tag.svg`,
  };
}

/**
 * This service registers helix icons with the MatIconRegistry.
 * Each icon can be accessed with `helix:<iconName>`
 */
@Injectable({
  providedIn: 'root',
})
export class IconService {
  /**
   * This flag is used to make sure the icon service is only ever initialized once.
   */
  initialized = false;

  constructor(
    protected helixAssetUrlPipe: HelixAssetUrlPipe,
    protected iconRegistry: MatIconRegistry,
    protected sanitizer: DomSanitizer,
  ) {}

  /**
   * Sanitize and register icon in a namespace.
   *
   * @param namespace Namespace for registering the icon
   * @param name Name of the icon used as shape in cog-icon
   * @param path Path of the svg file
   */
  registerIconInNamespace(namespace: string = '', name: string, path: string): void {
    this.iconRegistry.addSvgIconInNamespace(
      namespace,
      name,
      this.sanitizer.bypassSecurityTrustResourceUrl(this.helixAssetUrlPipe.transform(path))
    );
  }

  /**
   * Registers all of the Helix Icons with the MatIconRegistry.
   */
  registerAllIcons(icons: IconList) {
    for (const [name, path] of Object.entries(icons)) {
      this.registerIconInNamespace('helix', name, path);
    }
  }

  /**
   * Initial registration of all Helix Icons with the MatIconRegistry.
   */
  initializeIcons() {
    if (!this.initialized) {
      this.registerAllIcons(getIcons());
      this.initialized = true;
    }
  }
}
