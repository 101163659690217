/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HeliosBackupConfig } from '../models/helios-backup-config';
@Injectable({
  providedIn: 'root',
})
class ConfigService extends __BaseService {
  static readonly GetBackupConfigPath = '/backup/config';
  static readonly UpdateBackupConfigPath = '/backup/config';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get backup configuration.
   *
   * Retrieve the backup configuration details.
   * @return Success
   */
  GetBackupConfigResponse(): __Observable<__StrictHttpResponse<HeliosBackupConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/backup/config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosBackupConfig>;
      })
    );
  }
  /**
   * Get backup configuration.
   *
   * Retrieve the backup configuration details.
   * @return Success
   */
  GetBackupConfig(): __Observable<HeliosBackupConfig> {
    return this.GetBackupConfigResponse().pipe(
      __map(_r => _r.body as HeliosBackupConfig)
    );
  }

  /**
   * Update backup configuration.
   *
   * Update the backup configuration details.
   * @param body Backup configuration details to be updated.
   * @return Success
   */
  UpdateBackupConfigResponse(body: HeliosBackupConfig): __Observable<__StrictHttpResponse<HeliosBackupConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/backup/config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosBackupConfig>;
      })
    );
  }
  /**
   * Update backup configuration.
   *
   * Update the backup configuration details.
   * @param body Backup configuration details to be updated.
   * @return Success
   */
  UpdateBackupConfig(body: HeliosBackupConfig): __Observable<HeliosBackupConfig> {
    return this.UpdateBackupConfigResponse(body).pipe(
      __map(_r => _r.body as HeliosBackupConfig)
    );
  }
}

module ConfigService {
}

export { ConfigService }
