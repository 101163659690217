import { ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { AwsObjectProtectionRequestParams } from '@cohesity/api/v2';
import { Environment, RecoveryAction } from '@cohesity/iris-shared-constants';
import { CloudJobType } from '@cohesity/dataprotect/shared';

/**
 * Cloud Subscription Types
 */
export enum CloudSubscriptionType {
  kAzureCommercial = 1,
  kAzureGovCloud = 2,
  kAWSCommercial = 3,
  kAWSGovCloud = 4,
  kAzureStackCommercial = 5,
  kAWSC2S = 6,
  kAzureStackADFS = 7,
}

/**
 * Cloud Subscription Type Strings
 */
export enum CloudSubscription {
  kAzureCommercial = 'kAzureCommercial',
  kAzureGovCloud = 'kAzureGovCloud',
  kAWSCommercial = 'kAWSCommercial',
  kAWSGovCloud = 'kAWSGovCloud',
  kAzureStackCommercial = 'kAzureStackCommercial',
  kAWSC2S = 'kAWSC2S',
  kAzureStackADFS = 'kAzureStackADFS',
}

/**
 * Azure Stack Types
 */
export const AzureStackTypes: CloudSubscription[] = [
  CloudSubscription.kAzureStackCommercial,
  CloudSubscription.kAzureStackADFS,
];

/**
 * AWS Authentication Types
 */
export enum AwsAuthType {
  kUseIAMUser = 0,
  kUseIAMRole = 1,
  kUseIAMRolesAnywhere = 2,
}

/**
 * The various AWS Leaf types
 */
export enum AwsLeafType {
  kEC2 = 'kEC2Instance',
  kRDS = 'kRDSInstance',
  kRDSPostgresDb = 'kRDSPostgresDb',
  kAurora = 'kAuroraCluster',
  kAuroraClusterPostgresDb = 'kAuroraClusterPostgresDb',
  kS3 = 'kS3Bucket',
  kDynamoDB = 'kDynamoDBTable',
}

/**
 * The Cloud leaf to Job Type mapping
 */
export enum CloudLeafToJobType {
  kRDSInstance = 'kRDSSnapshotManager',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  kRDSPostgresDb = 'kRDSSnapshotManager',
  kAuroraCluster = 'kAuroraSnapshotManager',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  kAuroraClusterPostgresDb = 'kAuroraSnapshotManager',
  kS3Bucket = 'kAwsS3',
  kDynamoDBTable = 'kAwsDynamoDB',
  kEntraID = 'kAzureEntraID',
}

/**
 * The cloud Job type to AWS leaf associated.
 */
export enum CloudJobToAwsLeafType {
  kRDSSnapshotManager = 'kRDSInstance',
  kAuroraSnapshotManager = 'kAuroraCluster',
  kAwsAuroraPostgres = 'kAuroraClusterPostgresDb',
  kAwsRDSPostgres = 'kRDSPostgresDb',
}

/**
 * Mapping for various cloud protection types with keys to look inside the protectionGroup Params
 */
export const CloudProtectionTypeParamsMap: { [k in CloudJobType]?: keyof AwsObjectProtectionRequestParams } = {
  [CloudJobType.kRDSSnapshotManager]: 'rdsSnapshotManagerProtectionTypeParams',
  [CloudJobType.kAuroraSnapshotManager]: 'auroraSnapshotManagerProtectionTypeParams',
  [CloudJobType.kAwsRDSPostgresBackup]: 'rdsPostgresProtectionTypeParams',
  [CloudJobType.kAwsRDSPostgres]: 'rdsPostgresProtectionTypeParams',
  [CloudJobType.kAwsAuroraPostgres]: 'rdsPostgresProtectionTypeParams',
  [CloudJobType.kAwsDynamoDB]: 'dynamoDBProtectionTypeParams',
};

/**
 * Available Ingest type protection
 */
export const AwsIngestProtectionTypes = [
  'kAwsRDSPostgresBackup',
  'kAwsRDSPostgres',
  'kAwsAuroraPostgres',
];

/**
 * The types of Protection Type available for RDS/Aurora Postgres DBs.
 */
export type AwsPostgresProtectionType = 'kRDSSnapshotManager' | 'kAuroraSnapshotManager' | 'kAwsRDSPostgresBackup';

/**
 * The various AWS Tag types
 */
 export enum AwsTagType {
  kTag = 'kTag',
  kRDSTag = 'kRDSTag',
  kAuroraTag = 'kAuroraTag',
  kS3Tag = 'kS3Tag',
}

/**
 * The various AWS Leaf entities
 */
export const AwsLeafTypes: AwsLeafType[] = [
  AwsLeafType.kEC2,
  AwsLeafType.kRDS,
  AwsLeafType.kRDSPostgresDb,
  AwsLeafType.kAurora,
  AwsLeafType.kAuroraClusterPostgresDb,
  AwsLeafType.kS3,
  AwsLeafType.kDynamoDB,
];

/**
 * The various AWS DB Leaf entities
 */
export const AwsDBLeafTypes: AwsLeafType[] = [
  AwsLeafType.kRDS, AwsLeafType.kAurora,
];

/**
 * The various AWS DB Leaf entities
 */
export const AwsDBTypes: Environment[] = [
  Environment.kRDSSnapshotManager,
  Environment.kAuroraSnapshotManager,
];

/**
 * The various AWS S3 types.
 */
export const AwsS3Types: Environment[] = [
  Environment.kAwsS3,
];

/**
 * The Recover Action to Job Type mapping
 */
export enum RecoverActionToJobType {
  RecoverRDS = 'kRDSSnapshotManager',
  RecoverAurora = 'kAuroraSnapshotManager',
}

/**
 * Map of Protection Type to Recovery Action.
 */
export enum ProtectionTypeToRecoveryActionMap {
  kRDSSnapshotManager = RecoveryAction.RecoverRDS,
  kAuroraSnapshotManager = RecoveryAction.RecoverAurora,
  kAwsRDSPostgresBackup = RecoveryAction.RecoverRDSPostgres,
  kAwsRDSPostgres = RecoveryAction.RecoverRDSPostgres,
  kAwsAuroraPostgres = RecoveryAction.RecoverRDSPostgres,
  kAwsDynamoDB = RecoveryAction.RecoverAwsDynamoDB,
}

/**
 * The different tag types for AWS source
 */
export const AwsTagTypes = ['kTag', 'kRDSTag', 'kAuroraTag', 'kS3Tag'];

/**
 * Storage type for external target registration.
 */
export enum StorageType {
  AWS = 'AWS',
  AZURE = 'Azure',
  GCP = 'Google',
  ORACLE = 'Oracle',
  NAS = 'NAS',
  QSTART = 'QStarTape',
  S3C = 'S3Compatible',
  NETAPP = 'NetApp StorageGRID',
}

/**
 * Aws Storage classes
 */
export enum AwsStorageClass {
  S3 = 'S3',
  S3_IA = 'S3-IA',
  Glacier_Legacy = 'Glacier (Legacy)',
  S3_One_Zone = 'S3-One-Zone',
  S3_Intelligent = 'S3-Intelligent',
  S3_Glacier_Deep_Archive = 'S3-Glacier-Deep-Archive',
  S3_Glacier_FR = 'S3-Glacier-FR',
  S3_Glacier_IR = 'S3-Glacier-IR',
  S3_STS = 'S3-STS',
  Snowball_Edge = 'Snowball Edge',
}

export const ExternalTargetTypes = {
  [StorageType.AWS]: {
    name: 'AWS',
    label: 'enum.environment.kAWS',
    vaults : {
      standard: 'kAmazon',
      gov: 'kS3Gov',
      c2s: 'kAmazonC2S',
    },
    types: [
      {
        name: 'S3',
        hasC2S: true,
        hasGov: true,
        tieringSupported: true,
        isColdTier: false,
      },
      {
        name: 'S3-IA',
        hasC2S: true,
        hasGov: true,
        isColdTier: false,
      },
      {
        name: 'Glacier (Legacy)',
        hasC2S: true,
        hasGov: true,
        isColdTier: true,
      },
      {
        name: 'S3-One-Zone',
        hasC2S: true,
        hasGov: true,
        isColdTier: false,
      },
      {
        name: 'S3-Intelligent',
        hasC2S: true,
        hasGov: true,
        tieringSupported: false,
        isColdTier: false,
      },
      {
        name: 'S3-Glacier-Deep-Archive',
        hasC2S: true,
        hasGov: true,
        isColdTier: true,
      },
      {
        name: 'S3-Glacier-FR',
        hasC2S: true,
        hasGov: true,
        isColdTier: true,
      },
      {
        name: 'S3-Glacier-IR',
        hasC2S: true,
        hasGov: true,
        isColdTier: true,
      },
      {
        name: 'S3-STS',
        hasGov: true,
        hasC2S: true,
        tieringSupported: true,
        isColdTier: false,
      },
      {
        name: 'Snowball Edge',
        tieringSupported: true,
        isColdTier: true,
      },
    ]
  },
  [StorageType.AZURE]: {
    name: 'Azure',
    label: 'enum.environment.kAzure',
    types: [
      {
        name: 'Archive Blob',
        hasGov: true,
      },
      {
        name: 'Cool Blob',
        hasGov: true,
      },
      {
        name: 'Hot Blob',
        hasGov: true,
        tieringSupported: true,
      }
    ],
  },
  [StorageType.GCP]: {
    name: 'GCP',
    label: 'enum.environment.kGCP',
    types: [
      {
        name: 'Standard',
        tieringSupported: true,
      },
      {
        name: 'Coldline',
      },
      {
        name: 'Nearline',
      },
      {
        name: 'Archive',
      }
    ],
  },
  [StorageType.NETAPP]: {
    name: 'NetApp',
    label: 'enum.provider.kNetApp',
    tieringSupported: true,
  },
  [StorageType.ORACLE]: {
    name: 'Oracle',
    label: 'enum.environment.kOracle',
    types: [
      {
        name: 'Object Storage',
        tieringSupported: true,
      },
      {
        name: 'Archive Storage',
      }
    ],
  },
  [StorageType.NAS]: {
    name: 'NAS',
    label: 'enum.provider.kNas'
  },
  [StorageType.QSTART]: {
    name: 'QStar Tape',
    label: 'enum.provider.kQStar',
  },
  [StorageType.S3C]: {
    name: 'S3 Compatible',
    label: 'enum.provider.kS3C',
    types: [
      {
        name: 'Regular',
        tieringSupported: true,
      },
      {
        name: 'Tape Based',
      }
    ],
  },
};

/**
 * The available workload types for AWS.
 */
export enum AwsWorkloadType {
  kEC2 = 'kEC2Instance',
  kRDS = 'kRDSInstance',
  kS3 = 'kS3Bucket',
  kDynamoDB = 'kDynamoDBTable',
}

/**
 * Provides configuration data for source detail aws workload tabs.
 */
export const AwsWorkloadConfigs = {
  kEC2: {
    flag: 'dmsAwsRdsSupportEnabled',
    displayName: 'ec2',
    workloadType: AwsWorkloadType.kEC2,
  },
  kRDS: {
    flag: 'dmsAwsRdsSupportEnabled',
    displayName: 'rds',
    workloadType: AwsWorkloadType.kRDS,
  },
  kS3: {
    dmsFlag: 'dmsAwsS3Protection',
    displayName: 's3',
    workloadType: AwsWorkloadType.kS3,
  },
  kDynamoDB: {
    dmsFlag: 'awsDynamoDbEnabled',
    displayName: 'dynamoDb',
    workloadType: AwsWorkloadType.kDynamoDB
  }
};

/**
 * The Aws workload inclusion map. Used to compute `excludeAwsTypes` for the
 * source tree service.
 */
export const AwsWorkloadInclusionMap: {
  [key in AwsWorkloadType]: ProtectionSourcesServiceApi.ListProtectionSourcesParams['excludeAwsTypes']
} = {
  [AwsWorkloadType.kEC2]: [AwsLeafType.kEC2, AwsTagType.kTag],
  [AwsWorkloadType.kRDS]: [AwsLeafType.kRDS, AwsLeafType.kAurora, AwsTagType.kRDSTag, AwsTagType.kAuroraTag],
  [AwsWorkloadType.kS3]: [AwsLeafType.kS3, AwsTagType.kS3Tag],
  [AwsWorkloadType.kDynamoDB]: [AwsLeafType.kDynamoDB, AwsTagType.kTag],
};

/**
 * List of available Garbage Collection Modes for S3Compatible
 * and NAS targets.
 */
export const GarbageCollectionModes = [
  {
    name: 'storageOptimized',
    value: false,
    hint: 'storageOptimizedInfo'
  },
  {
    name: 'networkOptimized',
    value: true,
    hint: 'networkOptimizedInfo'
  },
];

/**
 * Various AWS Service types.
 */
export enum AwsServiceType {
  kEC2 = 'EC2',
  kRDS = 'RDS',
  kDRaaS = 'DRaaS',
  kS3 = 'S3',
  kDynamoDB = 'DynamoDB',
}

/**
 * Map of AWS Service type to string.
 *
 * Note - Registeration API uses k values and aws-cloud-verify uses string values.
 */
export const AwsServiceTypeMap: {[k in AwsServiceType]: string} = {
  [AwsServiceType.kEC2]: 'kEC2',
  [AwsServiceType.kRDS]: 'kRDS',
  [AwsServiceType.kDRaaS]: 'kDRaaS',
  [AwsServiceType.kS3]: 'kS3',
  [AwsServiceType.kDynamoDB]: 'kDynamoDB',
};

/**
 * Various AWS Service types for registration.
 */
export enum RegisterAwsServiceType {
  kEC2 = 'kEC2',
  kRDS = 'kRDS',
  kPostgres = 'kPostgres',
  kDynamoDB = 'kDynamoDB',
  kS3 = 'kS3',
}

/**
 * NFS versions.
 */
export const NfsVersion = {
  '3': 'NFSv3',
  '4': 'NFSv4',
  '4.0': 'NFSv4_0',
  '4.1': 'NFSv4_1',
  '4.2': 'NFSv4_2'
};

/**
 * Nfs version to security types map.
 */
export const SecurityTypesToNfsMap = {
  'NFSv3': ['Default', 'None', 'System'],
  'NFSv4': ['Default', 'None', 'System', 'KRB5', 'KRB5I', 'KRB5P'],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'NFSv4_0': ['Default', 'None', 'System', 'KRB5', 'KRB5I', 'KRB5P'],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'NFSv4_1': ['Default', 'None', 'System', 'KRB5', 'KRB5I', 'KRB5P'],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'NFSv4_2': ['Default', 'None', 'System', 'KRB5', 'KRB5I', 'KRB5P'],
};

/**
 * Maps name of target type used in external target API model and Form model.
 */
export const keyAcccessorMapForTargets = {
  [StorageType.AWS]: 'aws',
  [StorageType.AZURE]: 'azure',
  [StorageType.GCP]: 'gcp',
  [StorageType.ORACLE]: 'oracle',
  [StorageType.NAS]: 'nas',
  [StorageType.QSTART]: 'qstarTape',
  [StorageType.S3C]: 's3Comp',
};

/**
 * Number of maximum tags allowed
 */
export const maximumNumTags = 40;

/**
 * AWS Saas connection request types.
 */
export enum AwsSaasConnectionRequestType {
  kScaleUp = 'UP',
  kScaleDown = 'DOWN',
  kRetry = 'RETRY',
  kDelete = 'DELETE',
};

/**
 * Cluster type string mapping.
 */
export const ClusterTypeMap = {
  kPhysical: 'kPhysical',
  kVirtualRobo: 'kVirtualRobo',
  kMicrosoftCloud: 'kMicrosoftCloud',
  kAmazonCloud: 'kAmazonCloud',
  kGoogleCloud:'kGoogleCloud',
  kIBMCloud: 'kIBMCloud',
};

/**
 * Object lock type string mapping.
 */
export const objectLockTypeMap = {
  kAws: 'S3 Object Lock',
  kAzure: 'Blob Immutability',
  kGcp: 'Gcp Object Lock',
};

/**
 * Enum defining authentication status types
 */
export enum AuthenticationStatus {
  /**
   * Status indicating authentication process is pending
   */
  Pending = 'pending',
  /**
   * Status indicating authentication was successfully confirmed
   */
  Confirmed = 'confirmed',
  /**
   * Status indicating authentication failed
   */
  Failed = 'failed',
}

/**
 * GCP Storage class mapping.
 */
export const GCPStorageClassMap = {
  kStandard: 'Standard',
  kNearline: 'Nearline',
  kColdline: 'Coldline',
  kArchive: 'Archive',
};
