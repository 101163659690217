/**
 * Function to determine whether a given cluster version is newer than the
 * provided minimum cluster version.
 *
 * @param version The cluster version.
 * @param minVersion The minimum cluster version to compare to.
 * @return True if version is newer than minVersion.
 */
export function checkClusterVersion(version: string, minVersion: string) {
  if (['master', 'staging', '_team'].some(x => version.includes(x))) {
    // Actual cluster version is master or team branch.
    return true;
  }

  // Get the numerical semver version of the cluster. For example,
  // "6.6.0", "6.4.1", etc.
  const formattedVersion = version?.split('_')[0].replace(/[^0-9.]+/g, '');
  const formattedMinVersion = minVersion?.split('_')[0].replace(/[^0-9.]+/g, '');

  if (formattedVersion === formattedMinVersion) {
    // Actual cluster version is same as minimum cluster version.
    return true;
  }

  const clusterVersionParts = formattedVersion?.split('.');
  const minVersionParts = formattedMinVersion?.split('.');

  // Return whether the cluster version is newer than the minimum cluster.
  // For example, "6.6" is newer than "6.5.1".
  // TODO: need to handle _rm branches which are the latest of any dot release.
  for (let i = 0; i < clusterVersionParts?.length; i++) {
    const versionA = Number(clusterVersionParts[i] || 0);
    const versionB = Number(minVersionParts[i] || 0);

    if (versionA < versionB) {
      // Actual cluster version is older than minimum cluster version.
      return false;
    }

    if (versionA > versionB) {
      // Actual cluster version is newer than minimum cluster version.
      return true;
    }
  }

  // Actual cluster version is newer than minimum cluster version.
  return false;
}
