/**
 * This file contains a list of feature flag keys and their minimum required
 * on-prem cluster versions.
 *
 * This is necessary because starting with version 7.2 Helios serves the latest
 * HECP branch which is cut from master. Therefore the latest state of
 * feature-flags will be served to 7.2, 7.2.2, 7.3 etc. That means if a feature
 * is enabled in 7.3 it would also be rendered in 7.2 since flagEnabled doesn't
 * consider version. That function name is now deprecated and replaced with
 * featureEnabled which checks flag state and minimum cluster version.
 *
 * Note: We do not include this info in the feature-flags.json file, which
 * would have been efficient and convenient, because DevX did not want any
 * changes to that file.
 */

/**
 * List of feature flags and their minimum required versions.
 */
const minimumRequiredClusterVersion = {
  aTestFlag: '7.3', // Do not remove this flag. It is expected by the unit test.
  useV2APITimeSeriesStats: '7.3',
  patchV2Enhancements: '7.2.2',
} as const;

/**
 * Sets the flag name type according to what is found in the map.
 */
export type FlagKeys = keyof typeof minimumRequiredClusterVersion;

/**
 * Returns the minimum required cluster version for the indicated feature.
 *
 * @param flag UI Feature Flag string.
 * @returns version string.
 */
export function getMinimumRequiredClusterVersion(flag: FlagKeys) {
  return minimumRequiredClusterVersion[flag];
}
