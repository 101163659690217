/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HeliosBackupRunList } from '../models/helios-backup-run-list';
@Injectable({
  providedIn: 'root',
})
class HeliosBackupService extends __BaseService {
  static readonly ListHeliosBackupRunsPath = '/backups/list';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieve details of Helios Backup Runs.
   *
   * Retrieve a list of backup run statuses and details.
   * @param numRuns Specifies the number of backup runs to retrieve.
   * @return Success
   */
  ListHeliosBackupRunsResponse(numRuns?: number): __Observable<__StrictHttpResponse<HeliosBackupRunList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (numRuns != null) __params = __params.set('numRuns', numRuns.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/backups/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosBackupRunList>;
      })
    );
  }
  /**
   * Retrieve details of Helios Backup Runs.
   *
   * Retrieve a list of backup run statuses and details.
   * @param numRuns Specifies the number of backup runs to retrieve.
   * @return Success
   */
  ListHeliosBackupRuns(numRuns?: number): __Observable<HeliosBackupRunList> {
    return this.ListHeliosBackupRunsResponse(numRuns).pipe(
      __map(_r => _r.body as HeliosBackupRunList)
    );
  }
}

module HeliosBackupService {
}

export { HeliosBackupService }
