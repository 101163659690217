import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';

import { AddressBarComponent } from './address-bar/address-bar.component';
import { DirectoryListComponent } from './directory-list/directory-list.component';
import { VirtualisedDirectoryListComponent } from './virtualised-directory-list/virtualised-directory-list.component';
import { SharedModule } from '../shared.module';
import { PipesModule } from '../pipes/pipes.module';
import { MatBadgeModule } from '@angular/material/badge';

const COMPONENTS = [
  AddressBarComponent,
  DirectoryListComponent,
  VirtualisedDirectoryListComponent,
];

@NgModule({
  imports: [
    CommonModule,
    MatBadgeModule,
    PipesModule,
    ScrollingModule,
    SharedModule,
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class FolderBrowserModule { }
