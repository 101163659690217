/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HeliosBackupLog } from '../models/helios-backup-log';
@Injectable({
  providedIn: 'root',
})
class BackupService extends __BaseService {
  static readonly GetBackupLogsPath = '/backup/logs/{runId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get backup logs.
   *
   * Retrieve backup logs for a specific run.
   * @param runId The unique identifier for the backup run to retrieve logs for.
   * @return List of logs for the backup run.
   */
  GetBackupLogsResponse(runId: string): __Observable<__StrictHttpResponse<Array<HeliosBackupLog>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/backup/logs/${encodeURIComponent(runId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HeliosBackupLog>>;
      })
    );
  }
  /**
   * Get backup logs.
   *
   * Retrieve backup logs for a specific run.
   * @param runId The unique identifier for the backup run to retrieve logs for.
   * @return List of logs for the backup run.
   */
  GetBackupLogs(runId: string): __Observable<Array<HeliosBackupLog>> {
    return this.GetBackupLogsResponse(runId).pipe(
      __map(_r => _r.body as Array<HeliosBackupLog>)
    );
  }
}

module BackupService {
}

export { BackupService }
