<cog-table #table
  class="standard-width"
  name="virtualised-directory-list-table"
  cogDataId="virtualised-directory-list-table">

  <cdk-virtual-scroll-viewport class="virual-scroll-vp">

    <!-- New code - added spinner on the top. -->
    <div *ngIf="isLoading">
      <cog-spinner></cog-spinner>
    </div>
    <table mat-table
      matSort
      [dataSource]="derivedDataSource$  | async"
      matSortActive="name"
      (matSortChange)="sortData($event)"
      [trackBy]="trackBy"
      [class.loading]="isLoading">

      <!-- This uses custom selection logic in order to disable select all and to make children of selected directories
      show as selected, but disabled -->
      <ng-container matColumnDef="selection">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">

          <!-- Hide if the option to select the files if onlySelectFolders is true -->
          <ng-container *ngIf="!onlySelectFolders || row.isFolder">
            <mat-checkbox *ngIf="selection.isMultipleSelection()"
              cogDataId="select-{{row.name}}"
              [inheritDataId]="true"
              (change)="selectionUpdate(row)"
              (click)="table.stopPropagation($event)"
              [checked]="selection.isSelected(row) || isParentDirectorySelected"
              [disabled]="isRowDisabled(row)">
            </mat-checkbox>
            <mat-radio-button cogDataId="select-{{row.name}}"
              *ngIf="!selection.isMultipleSelection()"
              [inheritDataId]="true"
              (change)="selection.toggle(row); selectItem.emit(row)"
              (click)="table.stopPropagation($event)"
              [checked]="selection.isSelected(row)">
            </mat-radio-button>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'name' | translate }}</th>

        <td mat-cell *matCellDef="let row" class="directory-name">
          <ng-template #docNameTemplate>
            <cog-icon
              [shape]="rowIconProviderFn?.(row) ?? (row.type | fileIcon)"
              [matTooltip]="('enum.fileObjectType.' + row.type) | translate">
            </cog-icon>

            <div class="cog-table-object-body">{{ row.name }}</div>
          </ng-template>

          <a *ngIf="isRowNavigable(row); else document"
            class="cog-table-object"
            cogDataId="select-document-{{row.name}}"
            (click)="browseToPath.emit(row.fullPath); table.stopPropagation($event)">
            <ng-template [ngTemplateOutlet]="docNameTemplate"></ng-template>
          </a>

          <ng-template #document>
            <div class="cog-table-object">
              <ng-template [ngTemplateOutlet]="docNameTemplate"></ng-template>
            </div>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'size' | translate}}
        </th>
        <td mat-cell *matCellDef="let row">

          <span *ngIf="row.size && row.type === 'File'">
            {{row.size | byteSize}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastModifiedUsecs">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'lastModified' | translate}}
        </th>
        <td mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.lastModifiedUsecs > 0">
            {{row.lastModifiedUsecs | cohDate}}
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['selection', 'name', 'size', 'lastModifiedUsecs']"></tr>
      <ng-template  let-row matRowDef cdkVirtualFor
        [matRowDefColumns]="['selection', 'name', 'size', 'lastModifiedUsecs']" [cdkVirtualForOf]="documents$ | async"
        [hidden]="isLoading && !isPartialListing">
        <tr mat-row (click)="onRowClicked(row); table.stopPropagation($event)"></tr>
      </ng-template>

      <!-- NoData Footer -->
    <tr mat-footer-row class="nodata-row"
      [class.hidden]="isLoading || (documents$ | async).length"
      *matFooterRowDef="['noData']">
    </tr>
    <ng-container matColumnDef="noData">
      <td mat-footer-cell mat-footer-cell *matFooterCellDef>
        {{ 'noDataFound' | translate }}
      </td>
    </ng-container>

    </table>
  </cdk-virtual-scroll-viewport>
</cog-table>
