import { featureEnabled, getConfigByKey, isOneHeliosAppliance, isVaultCluster } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';
const clusterViewAccess = (ctx: StateAccessContext) => ctx.CLUSTER_VIEW;
const clusterViewApplianceManager = (ctx: StateAccessContext) =>
  isOneHeliosAppliance(ctx.irisContext) && ctx.CLUSTER_VIEW;
const kmsAccess = (ctx: StateAccessContext) => ctx.CLUSTER_VIEW && getConfigByKey(ctx.irisContext, 'cluster.keyManagementSystem', true);
const certificatesAccess = (ctx: StateAccessContext) => isOneHeliosAppliance(ctx.irisContext) && ctx.CLUSTER_VIEW;
const newNodesPageAccess = (ctx: StateAccessContext) => ctx.CLUSTER_VIEW && ctx.FEATURE_FLAGS.newNodesPage;
const clusterUpdateAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.ngClusterUpdate &&
  (ctx.CLUSTER_UPGRADE || ctx.CLUSTER_MAINTENANCE) && getConfigByKey(ctx.irisContext, 'softwareUpdate.update', true);
// Show software update if user has CLUSTER_VIEW privilege and apply finer
// controls within the software update page.
const clusterUpdateV2Access = (ctx: StateAccessContext) =>
  ((featureEnabled(ctx.irisContext, 'patchV2Enhancements') || isOneHeliosAppliance(ctx.irisContext) || getConfigByKey(ctx.irisContext, 'softwareUpdate.noLegacyPatchSupport', false)) &&
    ctx.CLUSTER_VIEW);
const clusterUpdateMaintenanceAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngClusterUpdate && ctx.CLUSTER_MAINTENANCE && getConfigByKey(ctx.irisContext, 'softwareUpdate.maintenance', true);
const clusterUpdateBaseOSAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngClusterUpdate && ctx.CLUSTER_MAINTENANCE && getConfigByKey(ctx.irisContext, 'softwareUpdate.maintenance', true);
const storageDomainsAccess = (ctx: StateAccessContext) =>
  ctx.STORAGE_DOMAIN_VIEW && ctx.FEATURE_FLAGS.ngStorageDomainsModule && !ctx.isTenantUser && !ctx.isHeliosTenantUser &&
  getConfigByKey(ctx.irisContext, 'cluster.storageDomain', true);
const storageDomainsDetailAccess = (ctx: StateAccessContext) =>
  ctx.STORAGE_DOMAIN_VIEW && ctx.FEATURE_FLAGS.ngStorageDomainsDetail && !ctx.isTenantUser && !ctx.isHeliosTenantUser;
const storageDomainsModifyAccess = (ctx: StateAccessContext) =>
  ctx.STORAGE_DOMAIN_MODIFY && ctx.FEATURE_FLAGS.ngStorageDomainsModify && !ctx.isTenantUser && !ctx.isHeliosTenantUser;
const smtpAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.applianceManagerUIChangesForGA && ctx.CLUSTER_VIEW;
const snmpAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.ngSnmp && ctx.CLUSTER_VIEW;
const vaultAccess = (ctx: StateAccessContext) => !isVaultCluster(ctx.irisContext) &&
  ctx.FEATURE_FLAGS.onPremVaultClusters &&
  getConfigByKey(ctx.irisContext, 'vault.features.allow', true);

export const ClusterConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'cluster-new.**',
      url: '^/cluster/',
      loadChildren: () => import('./cluster.module').then(m => m.ClusterModule),
    },
    {
      name: 'cluster-new.kms.**',
      url: '^/cluster/kms',
      loadChildren: () => import('./kms/kms.module').then(m => m.KmsModule),
    },
    {
      name: 'cluster-new.vault.**',
      url: '^/cluster/vault',
      loadChildren: () => import('./vault/vault.module').then(m => m.VaultModule),
    },
    {
      name: 'cluster-update.**',
      url: '/cluster/update',
      loadChildren: () => import('./cluster-update/cluster-update.module').then(m => m.ClusterUpdateModule),
    },
    {
      name: 'cluster-update.maintenance.**',
      url: '/cluster/update/maintenance',
      loadChildren: () => import('./cluster-update/cluster-update.module').then(m => m.ClusterUpdateModule),
    },
    {
      name: 'cluster-update.precheck-details.**',
      url: '/cluster/update/precheck-details',
      loadChildren: () => import('./cluster-update/cluster-update.module').then(m => m.ClusterUpdateModule),
    },
    {
      name: 'cluster-update.security-patches.**',
      url: '/cluster/update/security-patches',
      loadChildren: () => import('./cluster-update/cluster-update.module').then(m => m.ClusterUpdateModule),
    },
    {
      name: 'ngCluster-upgrade.**',
      url: '/cluster/upgrade',
      loadChildren: () => import('./cluster-upgrade/cluster-upgrade.module').then(m => m.ClusterUpgradeModule),
    },
    {
      name: 'ng-upgrade-detail.**',
      url: '/cluster/upgrade/detail?{operation}',
      loadChildren: () => import('./cluster-upgrade/cluster-upgrade.module').then(m => m.ClusterUpgradeModule),
    },
    {
      name: 'ng-precheck-detail.**',
      url: '/cluster/upgrade/precheck/detail?{operation}',
      loadChildren: () => import('./cluster-upgrade/cluster-upgrade.module').then(m => m.ClusterUpgradeModule),
    },
    {
      name: 'cluster-new.am-summary.**',
      url: '^/cluster/summary',
      loadChildren: () => import('./../appliance-manager/appliance-manager-cluster-summary/appliance-manager-cluster-summary.module').then(m => m.ApplianceManagerClusterSummaryModule),
    },
    {
      name: 'cluster-new.nodes.**',
      url: '^/cluster/hardware',
      loadChildren: () => import('./hardware/cluster-hardware.module').then(m => m.ClusterHardwareModule),
    },
    {
      name: 'node-hardware-details.**',
      url: '/node-details',
      loadChildren: () =>
        import('./hardware/containers/node-details/node-details.module').then(m => m.ClusterNodeDetailsModule),
    },
    {
      name: 'ng-storage-domains-modify.**',
      url: '^/cluster/storage-domains/modify?{storageDomainId}',
      loadChildren: () => import('./cluster.module').then(m => m.ClusterModule),
    },
    {
      name: 'ng-storage-domains-detail.**',
      url: '^/cluster/storage-domains/detail?{storageDomainId}',
      loadChildren: () => import('./cluster.module').then(m => m.ClusterModule),
    },
    {
      name: 'cluster.certificates.**',
      url: '^/cluster/certificates',
      loadChildren: () => import('./certificates/certificates.module').then(m => m.CertificatesModule),
    },
    {
      name: 'cluster.smtp.**',
      url: '^/cluster/smtp',
      loadChildren: () => import('./smtp/smtp.module').then(m => m.SmtpModule),
    },
    {
      name: 'cluster.snmp.**',
      url: '^/cluster/snmp',
      loadChildren: () => import('./snmp/snmp.module').then(m => m.SnmpModule),
    }
  ],

  allClusterMap: {
    cluster: false,
  },

  getStateAccessMap(): StateAccessMap {
    return {
      'am-upgrade-status': clusterUpdateV2Access,
      'cluster-new': clusterViewAccess,
      'cluster-new.kms': kmsAccess,
      'cluster-new.vault': vaultAccess,
      'cluster-new.am-summary': clusterViewApplianceManager,
      'cluster-new.nodes': clusterViewAccess,
      'cluster.certificates': certificatesAccess,
      'cluster-new.storage-domains-list': storageDomainsAccess,
      'cluster-update': clusterUpdateAccess,
      'cluster-update.maintenance': clusterUpdateMaintenanceAccess,
      'cluster-update.security-patches': clusterUpdateBaseOSAccess,
      'cluster-update.precheck-details': clusterUpdateAccess,
      'cluster.smtp': smtpAccess,
      'cluster.snmp': snmpAccess,
      'ngCluster-upgrade': clusterUpdateV2Access,
      'ng-upgrade-detail': clusterUpdateV2Access,
      'ng-precheck-detail': clusterUpdateV2Access,
      'ng-storage-domains-detail': storageDomainsDetailAccess,
      'ng-storage-domains-modify': storageDomainsModifyAccess,
      'node-hardware-details': newNodesPageAccess,
    };
  },
};
