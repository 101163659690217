/**
 * Maximum no. of nodes allowed for wide spacing graph
 */
export const maxNumNodeWideSpacing = 10;

/**
 * Additional attributes to support custom style and show tooltip based on activities.
 * Mainly for RPaaS.
 */
interface TopologyBaseElement {
  style?: string;
  activities?: any;
  dateRange?: any;
}

/**
 * Model for node in a topology graph
 */
export interface TopologyNode extends TopologyBaseElement {
  id: string;
  label: string;
  type: string;
  name?: string;
  status?: string;
  icon?: string;
}

/**
 * Model for a directed edge in a topology graph
 */
export interface TopologyEdge extends TopologyBaseElement {
  from: string;
  to: string;
  biDirection?: boolean;
}

/**
 * Model for an element (node or edge) in a topology graph. One use case is tooltip.
 */
export type TopologyElement = TopologyNode | TopologyEdge;

/**
 * Model for a topology graph
 */
export class TopologyGraph {
  /**
   * Constructor.
   */
  constructor(
    public nodes: TopologyNode[] = [],
    public edges: TopologyEdge[] = [],
    public isSingleCluster: boolean = false,
    public scale?: number,
    public showScale?: boolean,
    public clickForTooltip?: boolean,
    public minHeight?: number,
  ) {}

  /**
   * Add a node to the graph.
   *
   * @param    newNode    the node to be added
   * @param    skipCheck  skip the checking if node already exists in the graph
   * @param    fromId     add edge from fromId to this node if fromId is provided.
   *                      Note that skipCheck also apply when adding edge
   */
  addNode(newNode: TopologyNode, skipCheck = false, fromId?: string) {
    if (skipCheck || !this.nodes.find(node => node.id === newNode.id)) {
      this.nodes.push(newNode);
    }
    if (fromId) {
      // Find edge in reverse direction
      const edge = skipCheck ? undefined : this.edges.find(e => e.from === newNode.id && e.to === fromId);

      if (edge) {
        edge.biDirection = true;
      } else {
        this.edges.push({ from: fromId, to: newNode.id });
      }
    }
  }

  /**
   * Truncate the node names based on how many nodes.
   */
  truncateNodeName() {
    // shorter length when there are more nodes as there will be less spacing in topology graph
    const maxLength = this.nodes.length > maxNumNodeWideSpacing ? 11 : 20;

    this.nodes.forEach(node => {
      if (node?.type === 'cluster' && node?.label?.length > maxLength) {
        node.label = node.label.substring(0, maxLength - 3) + '...';
      }
    });
    return this;
  }
}
