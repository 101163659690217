import { Injectable } from '@angular/core';
import { VirtualScrollStrategy, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Subject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

// Define Virtual Scrolling Strategy for custom virtual scroll
// Refer virtualised-directory-list.component.ts for usage
// Steps to follow:
// 1. Import VIRTUAL_SCROLL_STRATEGY in the provider as
//  useClass TableVirtualScrollStrategy
// 2. Inject TableVirtualScrollStrategy as scrollStrategy(say)
//  in the constructor
// 3. Set setScrollHeight in the onInit function
// 4. Listen for the scrolledIndexChange event & update the table data
//  as per the current index.
@Injectable()
export class TableVirtualScrollStrategy implements VirtualScrollStrategy {

  // Height of a table row
  private scrollHeight!: number;
  // Height of the table header row
  private scrollHeader!: number;
  // Emits the indexchange for scroll
  private readonly indexChange = new Subject<number>();

  // The CDk viewport which will have this strategy
  private viewport: CdkVirtualScrollViewport;

  // The observable for indexChange
  public scrolledIndexChange: Observable<number>;

  constructor() {
    this.scrolledIndexChange = this.indexChange.asObservable().pipe(distinctUntilChanged());
  }

  /**
   * Attaches this scroll strategy to a cdk viewport.
   *
   * @param viewport The viewport to attach this strategy to.
   */
  public attach(viewport: CdkVirtualScrollViewport): void {
    this.viewport = viewport;
    this.onDataLengthChanged();
    this.updateContent(viewport);
  }

  /**
   * Detaches this scroll strategy from the currently attached viewport.
   */
  public detach(): void {
    // no-op
  }

  /**
   * Called when the viewport is scrolled
   * (debounced using requestAnimationFrame).
   */
  public onContentScrolled(): void {
    this.updateContent(this.viewport);
  }

  /**
   * Called when the length of the data changes.
   */
  public onDataLengthChanged(): void {
    if (this.viewport) {
      this.viewport.setTotalContentSize(this.viewport.getDataLength() * this.scrollHeight);
    }
  }

  /**
   * Called when the range of items rendered in the DOM has changed.
   */
  public onContentRendered(): void {
    // no-op
  }

  /**
   * Called when the offset of the rendered items changed.
   */
  public onRenderedOffsetChanged(): void {
    // no-op
  }

  /**
   * Scroll to the offset for the given index.
   */
  public scrollToIndex(): void {
    // no-op
  }

  /**
   * Set the rowHeight & headerheight
   * these are used to calculate the current index of the scroll
   *
   * @param rowHeight Height of a table row
   * @param headerHeight Height of the table header
   */
  public setScrollHeight(rowHeight: number, headerHeight: number) {
    this.scrollHeight = rowHeight;
    this.scrollHeader = headerHeight;
    this.updateContent(this.viewport);
  }

  /**
   * Calculate the current scrolled index
   * and emit the value
   *
   * @param viewport The CdkVirtualScrollViewport
   */
  private updateContent(viewport: CdkVirtualScrollViewport) {
    if (this.viewport) {
      const newIndex =
        Math.max(0, Math.round((viewport.measureScrollOffset() - this.scrollHeader) / this.scrollHeight) - 2);
      viewport.setRenderedContentOffset(this.scrollHeight * newIndex);
      this.indexChange.next(
        Math.round((viewport.measureScrollOffset() - this.scrollHeader) / this.scrollHeight) + 1
      );
    }
  }
}
