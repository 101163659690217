// this is needed in order for transition function to work
import 'd3-transition';

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  isDevMode,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { BaseType, event, select, Selection } from 'd3-selection';
import { curveLinear, line } from 'd3-shape';
import { graphlib, layout } from 'dagre';
import { ScopeSelectorService } from 'src/app/core/services';

import { TopologyElement, TopologyGraph, maxNumNodeWideSpacing } from './topology-graph';

const GRAPH_PADDING = {
  x: 32,
  y: 0
};
const NODE_RADIUS = 18;
const NODE_SIZE = NODE_RADIUS * 2;

// Used to find point right outside the node circle
const NODE_RADIUS_SQUARE = (NODE_RADIUS + 1) * (NODE_RADIUS + 1);

// d3 curve style can be curveLinear, curveStep, curveStepBefore,
// curveStepAfter, curveBasis, curveCardinal, curveMonotoneX, curveCatmullRom
const EDGE_STYLE = curveLinear;
const ICON_SIZE = 24;
const ICON_FOLDER = '/assets/i/icn/';

/**
 * Maps the node type to icon file name
 */
const ICON_MAP = {
  default: 'graph-cluster',
  cluster: 'graph-cluster',
  local: 'graph-cluster',
  remote: 'graph-remote-cluster',
  kCloudSpill: 'graph-cloud',
  kArchival: 'graph-cloud',
  tape: 'graph-tape',

  // For RPaaS
  vault: 'graph-vault',
};

/**
 * Maps the node type to class name
 */
const CLASS_MAP = {
  local: 'local',
  remote: 'remote',
  kCloudSpill: 'cloud',
  kArchival: 'archive',
  tape: 'archive',

  // For RPaaS
  disconnected: 'disconnected',
  error: 'error',
  noActivity: 'noActivity',
  inProgress: 'inProgress',
  success: 'success',
  warning: 'warning',
};

/**
 * Stores the coordinates of a point
 */
interface Point {
  x: number;
  y: number;
}

/**
 * Size of svg graph from start (top-left corner, usually (0,0)) to
 * end (bottom-right corner)
 */
interface Range {
  start: Point;
  end: Point;
}

/**
 * Gets the square of distance from 2 points
 *
 * @param     p1    First point
 * @param     p2    Second point
 * @returns   The square of distance from p1 to p2
 */
function getDistanceSquare(p1: Point, p2: Point): number {
  const xDiff = p1.x - p2.x;
  const yDiff = p1.y - p2.y;

  return xDiff * xDiff + (yDiff * yDiff);
}

/**
 * A helper function to get the min/max of x,y from 2 points
 *
 * @param     p     First point
 * @param     x     x-coordinate of second point
 * @param     y     y-coordinate of second point
 * @param     fn    either Math.min, Math.max
 * @returns   The min/max of x,y from 2 points
 */
function minMaxPoint(p: Point, x: number, y: number, fn: (...n: number[]) => number): Point {
  return <Point>{
    x: fn(p.x, x),
    y: fn(p.y, y)
  };
}

/**
 * Show ellipsis when text is too long.
 */
function wrap() {
  const self = select(this);
  if (self.node().getComputedTextLength) {
    let textLength = self.node().getComputedTextLength();
    let text = self.text();

    while (textLength > 130 && text.length > 0) {
      text = text.slice(0, -1);
      self.html(text + '&#8230;');
      textLength = self.node().getComputedTextLength();
    }
  }
}

/**
 * @description
 * This class shows the topology graph using dagre for layout and d3 for
 * rendering based on a data structure that contains a list of nodes and a
 * list of edges.
 *
 * @example
 *   <coh-topology-graph [data]="data"></coh-topology-graph>
 */
@Component({
  selector: 'coh-topology-graph',
  templateUrl: './topology-graph.component.html',
  styleUrls: ['./topology-graph.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopologyGraphComponent implements OnInit {
  /**
   * Topology graph container element reference.
   */
  @ViewChild('container', { static: true }) container: ElementRef;

  /**
   * Tooltip element reference.
   */
  @ViewChild('tooltip', { static: true }) topologyTooltip: ElementRef;

  /**
   * Input data that contains the graph definition, i.e. nodes and edges
   */
  private _data: TopologyGraph;

  /**
   * Returns the graph data.
   */
  @Input() get data(): TopologyGraph {
    return this._data;
  }

  /**
   * Sets graph data and render graph.
   */
  set data(data: TopologyGraph) {
    this._data = data;
    if (this.isInitialized) {
      this.shouldSetNodeSep();
      this.createLayout();
      this.render();
    }
  }

  /**
   * List of allowable scale for zoom in/out for the slider
   */
  readonly scaleList = [0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2];

  /**
   * Scale Index of the graph
   */
  _scaleIndex: number;

  /**
   * Gets the scale index of the graph.
   */
  get scaleIndex(): number {
    return this._scaleIndex;
  }

  /**
   * Sets the scale index of the graph and re-render.
   */
  set scaleIndex(scaleIndex: number) {
    this._scaleIndex = Math.min(Math.max(scaleIndex, 0), this.scaleList.length - 1);
    this.setSize();
  }

  /**
   * Gets the scale of the graph.
   */
  get scale(): number {
    return this.scaleList[this._scaleIndex];
  }

  /**
   * Sets the scale of the graph based on pre-set values in scaleList.
   */
  set scale(scaleValue: number) {
    const index = this.scaleList.findIndex(scale => scale >= scaleValue);

    this._scaleIndex = index === -1 ? this.scaleList.length - 1 : index;
  }

  /**
   * Flag indicates if component is initialized to avoid rendering twice
   */
  isInitialized = false;

  /**
   * A dagre layout which contains nodes/edges with x,y-coordinates
   */
  layout: graphlib.Graph;

  /**
   * A d3 svg selection used to add nodes and edges in the svg in html
   */
  svg: Selection<BaseType, any, HTMLElement, any>;

  /**
   * Mouse position when tooltip is triggered
   */
  tooltipMousePosition: Point;

  /**
   * The node or edge object being mouseover. It is used for showing tooltip.
   */
  graphElement: TopologyElement;

  /**
   * Number of pixels separates nodes horizontally based on windows width
   */
  nodeSep: number;

  /**
   * Additional distance from (0,0) to draw nodes/edges, sometimes dagre will
   * create negative coordinates. This variable is to offset the negative
   * coordinates so that graph will not be drawn outside visible area
   */
  offset: Point;

  /**
   * Range of graph from top-left (start) to bottom-right (end)
   */
  range: Range;

  /**
   * SVG container custom styles
   */
  svgStyle = {
    width: 0,
    height: 0,
    marginTop: 0
  };

  /**
   * Under single cluster case, all edges will be from the selected cluster.
   * This will cause dagre to layout the graph to have selected cluster on the
   * on the left and all other cluster/cloud/tape (node) on the right. To draw a
   * better looking graph, we want to split the nodes so that the selected
   * cluster is in the middle, 1st half of the nodes are on the left and 2nd
   * half on the right.
   * This variable is to store the index of edges in the middle for splitting
   * the edges under the single cluster case.
   */
  edgesSplitIndex: number;

  /**
   * Handle click outside tooltip. Only needed when we show tooltip from mouse click.
   */
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(clickEvent) {
    if (this.data?.clickForTooltip &&
      !this.topologyTooltip.nativeElement.contains(clickEvent.target)) {
      this.graphElement = undefined;
    }
  }

  /**
   * Constructor
   */
  constructor(
    private cdr: ChangeDetectorRef,
    private scopeSelectorService: ScopeSelectorService,
    private $state: StateService
  ) {}

  /**
   * For logging the x,y-coordinates of nodes and edges created by dagre,
   * mainly for debugging purpose
   */
  logLayout() {
    if (isDevMode()) {
      this.layout.nodes().forEach((v) => {
        console.log(`Node ${v}: ${JSON.stringify(this.layout.node(v))}`);
      });
      this.layout.edges().forEach((e) => {
        console.log(`Edge ${e.v} -> ${e.w}: ${JSON.stringify(this.layout.edge(e))}`);
      });
    }
  }

  /**
   * Calculate pixels between nodes (nodeSep) on initial load or window resize.
   *
   * @returns   True if value is changed which is then used to decide redo layout and render.
   */
  shouldSetNodeSep(): boolean {
    let nodeSep = 64;

    if (this.data) {
      if (this.data.isSingleCluster) {
        if (window.innerWidth > 1500) {
          nodeSep += Math.round((window.innerWidth - 1500) / 16);
        }
      } else {
        nodeSep = this.data.nodes.length > maxNumNodeWideSpacing ? 48 : 96;
      }
    }

    if (nodeSep !== this.nodeSep) {
      this.nodeSep = nodeSep;
      return true;
    }
    return false;
  }

  /**
   * Gets the range of the graph layout from start (top-left corner, usually
   * (0,0)) to end (bottom-right corner), also updates the offset for drawing
   * the nodes/edges after range is known. This will update 2 member variables:
   * range and offset.
   */
  getLayoutRange() {
    // Resets range; needs this for initial page load as well as resize
    this.range = {
      start: { x: 0, y: 0 },
      end: { x: 0, y: 0 }
    };

    // Finds the range from nodes first
    this.layout.nodes().forEach(v => {
      const node = this.layout.node(v);
      const increment = NODE_RADIUS;
      this.range.start = minMaxPoint(this.range.start, node.x - increment, node.y - increment, Math.min);
      this.range.end = minMaxPoint(this.range.end, node.x + increment, node.y + increment, Math.max);
    });

    // Updates the range with edges info
    this.layout.edges().forEach(e => {
      this.layout.edge(e).points.forEach((p) => {
        this.range.start = minMaxPoint(this.range.start, p.x, p.y, Math.min);
        this.range.end = minMaxPoint(this.range.end, p.x, p.y, Math.max);
      });
    });

    // Sets offset after range is known, mainly used to handle cases when
    // edge/node has negative coordinates
    this.offset = {
      x: GRAPH_PADDING.x - Math.min(0, Math.floor(this.range.start.x)),
      y: GRAPH_PADDING.y - Math.min(0, Math.floor(this.range.start.y))
    };
  }

  /**
   * Set sizes and default zoom/scale for topology graph
   */
  setSize() {
    if (!this.svg) {
      return;
    }

    const graphWidth = this.range.end.x + this.offset.x + (NODE_SIZE / 2) + GRAPH_PADDING.x;
    const graphHeight = this.range.end.y + this.offset.y + (NODE_SIZE / 2) + GRAPH_PADDING.y;

    if (this._scaleIndex === undefined) {
      // For larger number of nodes, scale is set to 1 (instead of 1.25) to
      // accommodate longer labels while still fit inside the tile.
      this.scale = this.data.scale ? this.data.scale : this.data.nodes.length <= 2 ? 1.5 : 1;
    }

    this.svgStyle.width = Math.round(graphWidth * this.scale);
    this.svgStyle.height = Math.max(this.data.minHeight || 0, Math.round(graphHeight * this.scale));

    const translateX = Math.min(0, Math.round((this.svgStyle.width - graphWidth) / 2 / this.scale));
    const translateY = Math.round((this.svgStyle.height - graphHeight) / 2 / this.scale);

    // TODO(ang): may need to find out why container width is smaller than expected on
    // page load in case we need to customize scale
    const containerHeight = this.container.nativeElement.offsetHeight;
    const ratio = this.data.nodes.length > 5 ? 0.4 : 0.6;
    this.svgStyle.marginTop = containerHeight > this.svgStyle.height ?
      Math.round((containerHeight - this.svgStyle.height) * ratio) : 0;

    this.svg.attr(
      'transform',
      `scale(${this.scale}) translate(${translateX}, ${translateY})`
    );
    this.svg
      .attr('width',  graphWidth)
      .attr('height', graphHeight);
  }

  /**
   * Creates graph layout using dagre based in input data
   */
  createLayout() {
    if (!this.data || !this.data.nodes) {
      return;
    }

    // Creates a new directed graph use dagre
    this.layout = new graphlib.Graph();

    // Setup graph configuration. Reference:
    //   https://github.com/dagrejs/dagre/wiki#configuring-the-layout
    this.layout.setGraph({
      marginx: 18,
      marginy: 3,
      ranker: 'longest-path',

      // Shows graph with more spacing and horizontal orientation with 5 nodes
      // or less or single cluster
      ...(this.data.nodes.length <= 3 || this.data.isSingleCluster ?
        {
          rankdir: 'LR',
          nodesep: 32,
          ranksep: this.nodeSep
        } :
        {
          rankdir: 'TB',
          nodesep: this.nodeSep,
          ranksep: 64
        }
      )
    });

    // Default to assigning a new object as a label for each new edge.
    this.layout.setDefaultEdgeLabel(() => ({}));

    // Adds nodes to the graph. The first argument is the node id. The second is
    // metadata about the node.
    this.data.nodes.forEach((node, index) => {
      this.layout.setNode(node.id, {
        index: index,
        label: node.label,
        type: node.type,
        status: node.status,
        style: node.style,
        icon: node.icon,
        width: NODE_SIZE,
        height: NODE_SIZE,
      });
    });

    // Only set split index if it is single cluster view. Otherwise, it is set
    // to zero which means not split.
    this.edgesSplitIndex = this.data.isSingleCluster ? Math.floor(this.data.edges.length / 2) : 0;

    // Adds edges to the graph.
    if (this.data.edges) {
      this.data.edges.forEach((edge, index) => {
        if (index < this.edgesSplitIndex) {
          // Reverses the direction for the first half edges under single cluster case
          this.layout.setEdge(edge.to, edge.from, { style: edge.style });
        } else {
          this.layout.setEdge(edge.from, edge.to, { style: edge.style });
        }
      });
    }

    layout(this.layout);
    // this.logLayout();
    this.getLayoutRange();
  }

  /**
   * dagre will provide the end point of edge based on the node being
   * rectangular resulting in edge not touching the node circle (especially at
   * corner). This function is to calculate the point at the circumference of
   * the node circle so that the edge touches the node
   *
   * The algorithm is to draw a straight line from the point to the center of
   * node circle. Then moving along the line until we find the point lie right
   * outside the circle circumference.
   *
   * @param     point      end of edge that may or may not touch the node circle
   * @param     nodeIndex  index of node in the layout
   * @returns   The point that lies right outside/on the circle circumference
   */
  getTouchPoint(point: Point, nodeIndex: string): Point {
    const node = this.layout.node(nodeIndex);
    const result = { ...point };

    // If the edge lies vertically or horizontally, it already touches the
    // circle so just return the point directly
    if ((node.y === point.y && Math.abs(node.x - point.x) === NODE_RADIUS) ||
        (node.x === point.x && Math.abs(node.y - point.y) === NODE_RADIUS)) {
      return result;
    }

    // Checks the x,y difference and increment the one with bigger difference
    const isIterateX = Math.abs(node.x - point.x) >= Math.abs(node.y - point.y);
    let increment: number;
    let gradient: number;

    if (isIterateX) {
      // Sets the direction of increment based on x or y
      increment = node.x > point.x ? 1 : -1;

      // Used to calculate y in the loop after x is updated
      gradient = (node.y - point.y) / (node.x - point.x);
    } else {
      increment = node.y > point.y ? 1 : -1;
      gradient = (node.x - point.x) / (node.y - point.y);
    }

    // Iterates points closer to center along the line from point to node center
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (isIterateX) {
        result.x += increment;
        result.y = (result.x - point.x) * gradient + point.y;
      } else {
        result.y += increment;
        result.x = (result.y - point.y) * gradient + point.x;
      }

      // Returns result if it is close enough
      if (getDistanceSquare(result, node) < NODE_RADIUS_SQUARE) {
        return result;
      }
    }
  }

  /**
   * Calculates the position (left or right) of tooltip based on mouse position
   * and by checking if there is enough space on the right.
   *
   * @returns   ngStyle object contains position info
   */
  getTooltipPosition() {
    if (!this.tooltipMousePosition || !this.graphElement) {
      return {};
    }

    const extra = this.data.clickForTooltip ? 50 : 0;
    const result = {
      'bottom.px': window.innerHeight - this.tooltipMousePosition.y - 24 - extra
    };

    // Check if tooltip will spill over page width. 250 is the expected tooltip
    // width. Getting it from after rendering (which is separate component and
    // is after API call) is hard so hardcoding it now.
    if (this.tooltipMousePosition.x + 250 <= window.innerWidth) {
      result['left.px'] = this.tooltipMousePosition.x + NODE_RADIUS;
    } else {
      result['right.px'] = window.innerWidth - this.tooltipMousePosition.x + NODE_RADIUS;
    }
    return result;
  }

  /**
   * Toggles tooltip display and send event to parent for populating tooltip data
   *
   * @param     element    d3 selection which is the element for node or edge
   * @param     mEvent     mouse event: when mouseover, shows tooltip; when
   *                       moustout, event will be undefined and hides tooltip
   * @param     item       node or edge being mouseover
   */
  toggleTooltip(
    element: Selection<BaseType, any, HTMLElement, any>,
    mEvent?: MouseEvent,
    item?: TopologyElement
  ) {
    if (mEvent) {
      element.style('filter', 'url(#shadow)');
      this.graphElement = item;
      this.tooltipMousePosition = {
        x: mEvent.x,
        y: mEvent.y
      };
    } else {
      element.style('filter', 'none');
      this.graphElement = undefined;
    }
    this.cdr.detectChanges();
  }

  /**
   * Draws the edges of graph
   */
  drawEdges() {
    const pathFunction = line()
      .x(d => d[0] + this.offset.x)
      .y(d => d[1] + this.offset.y)
      .curve(EDGE_STYLE);

    this.layout.edges().forEach((e, i) => {
      const classList = ['topology-edge'];
      const { points, style } = this.layout.edge(e);
      let arrowStart = '#dpt-arrow-start';
      let arrowEnd = '#dpt-arrow-end';

      if (CLASS_MAP[style]) {
        classList.push(`topology-edge-${CLASS_MAP[style]}`);
        arrowStart += `-${style}`;
        arrowEnd += `-${style}`;
      }
      arrowStart = `url(${arrowStart})`;
      arrowEnd = `url(${arrowEnd})`;

      // Replaces start and end points with points closer to node circle so
      // that the edge arrows are touching the node circle
      points[0] = this.getTouchPoint(points[0], e.v);
      points[points.length - 1] = this.getTouchPoint(points[points.length - 1], e.w);

      // d3 function requires [number, number], {x: number, y: number} so need
      // to map it
      const mappedPoints = points.map(p => <[number, number]>[ p.x, p.y ]);
      const path = this.svg.append('svg:path')
        .attr('class', classList.join(' '))
        .attr('d', pathFunction(mappedPoints));

      // Draws the arrow at both start and end of edge when it is bidirectional
      if (this.data.edges[i].biDirection) {
        path.attr('marker-start', arrowStart);
        path.attr('marker-end', arrowEnd);

      // For first half of edges, the direction is reversed so the arrow needs
      // to be drawn at start instead of end
      } else if (i < this.edgesSplitIndex) {
        path.attr('marker-start', arrowStart);
      } else {
        path.attr('marker-end', arrowEnd);
      }

      // TODO(ang): enable tooltip when api is available (6.5)
      // Needs to use function() here, not fat arrow function, because of the
      // need to reference scope of function (this).
      // path.on('mouseover', function onMouseOver() {
      //   self.toggleTooltip(select(this), event, self.data.edges[i]);
      // })
      // .on('mouseout', function onMouseOut() {
      //   self.toggleTooltip(select(this));
      // });
    });
  }

  /**
   * Draws the nodes of graph. Each node contains 3 items: circle, icon and label
   */
  drawNodes() {
    const self = this;
    const isMcm = this.scopeSelectorService.isMcm;
    const d3Nodes = this.svg.selectAll('circle.nodes')
      .data(this.layout.nodes())
      .enter()
      .append('g')
      .attr('class', d => {
        const classList = [ 'topology-node' ];
        const { style, type } = this.layout.node(d);

        if (!this.data.clickForTooltip && (
          type === 'local' ||
          (type === 'cluster' && this.data.isSingleCluster) ||
          (type !== 'cluster' && !this.data.isSingleCluster)
        )) {
          classList.push('topology-node-no-access');
        }

        if (CLASS_MAP[style]) {
          classList.push(`topology-node-${CLASS_MAP[style]}`);
        } else if (CLASS_MAP[type]) {
          classList.push(`topology-node-${CLASS_MAP[type]}`);
        }

        return classList.join(' ');
      })
      .on('click', d => {
        if (!this.data.clickForTooltip) {
          const { index, type } = this.layout.node(d);
          const { id } = this.data.nodes[index];
          const [ clusterId, clusterIncarnationId ] = id.split(':').map(Number);

          if (this.data.isSingleCluster) {
            if (type === 'remote') {
              if (isMcm) {
                this.scopeSelectorService.switchScope({clusterId, clusterIncarnationId}, false, true);
              } else {
                this.$state.go('remote-clusters-view', { clusterId });
              }
            } else if (['kCloudSpill', 'kArchival', 'tape'].includes(type)) {
              this.$state.go('external-targets-view', { id: id });
            }
          } else {
            if (['cluster', 'remote'].includes(type)) {
              this.scopeSelectorService.switchScope({clusterId, clusterIncarnationId}, false, true);
            }
          }
        }
      });

    const d3Circles = d3Nodes.append('g');

    if (this.data.clickForTooltip) {
      d3Circles.on('click', function onClick(d) {
        const {index} = self.layout.node(d);

        self.toggleTooltip(
          select(this).select('circle'),
          event,
          self.data.nodes[index]
        );
      });
    } else {
      // Needs to use function() here, not fat arrow function, because of the
      // need to reference scope of function (this).
      d3Circles.on('mouseenter', function onMouseOver(d) {
        const { index } = self.layout.node(d);

        self.toggleTooltip(
          select(this).select('circle'),
          event,
          self.data.nodes[index]
        );
      })
      .on('mouseleave', function onMouseOut() {
        self.toggleTooltip(select(this).select('circle'));
      });
    }

    d3Circles.append('svg:circle')
      .attr('cx', d => this.layout.node(d).x + this.offset.x)
      .attr('cy', d => this.layout.node(d).y + this.offset.y)
      .attr('r', `${NODE_RADIUS}px`);

    d3Circles.append('image')
      // TODO(ang): update icon based on this.layout.node(d).status when it is available
      .attr('xlink:href',  d =>
        `${ICON_FOLDER}${this.layout.node(d).icon || ICON_MAP[this.layout.node(d).type] || ICON_MAP.default}.svg`)
      .attr('x', d => this.layout.node(d).x + this.offset.x - (ICON_SIZE / 2))
      .attr('y', d => this.layout.node(d).y + this.offset.y - (ICON_SIZE / 2))
      .attr('width', ICON_SIZE)
      .attr('height', ICON_SIZE);

    d3Nodes.append('text')
      .text(d => this.layout.node(d).label)
      .attr('x', d => this.layout.node(d).x + this.offset.x)

      // make text closer to node (3px) so that when zoom in without font size change, it
      // will not be shown too far from it
      .attr('y', d => this.layout.node(d).y + this.offset.y + NODE_SIZE - 3)
      .each(wrap);
  }

  /**
   * Draws the topology graph use d3
   */
  render() {
    if (this.layout) {
      const container = select<SVGElement, any>(this.container.nativeElement);

      // create the svg graph and set the size
      this.svg = container.select('svg');
      this.setSize();

      // reset graph - remove all nodes and edges
      container.selectAll('svg > path').remove();
      container.selectAll('svg > g').remove();

      this.drawEdges();
      this.drawNodes();
    }
  }

  /**
   * Re-render or Resize graph when window resize
   */
  resize() {
    if (this.shouldSetNodeSep()) {
      this.createLayout();
      this.render();
    } else {
      this.setSize();
    }
  }

  /**
   * Creates the graph layout and then render it based on input data
   */
  ngOnInit() {
    this.shouldSetNodeSep();
    this.createLayout();
    this.render();
    this.isInitialized = true;
  }
}
