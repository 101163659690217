import { Pipe, PipeTransform } from '@angular/core';
import { isFinite } from 'lodash-es';
import moment from 'moment';

import { HelixIntlService } from '../../helix-intl.service';

/**
 * @ngdoc Filter
 * @description
 *   Wrapper for moment.js to render a string that indicates how long ago a
 *   usec value occurred from the current cluster time.
 *
 *
 * @example
 *   {{ startTimeUsecs | humanizeFromUsecs }} => '7 days ago'
 */
@Pipe({
  name: 'humanizeFromUsecs',
  standalone: true
})
export class HumanizeFromUsecsPipe implements PipeTransform {
  constructor(public intl: HelixIntlService) { }

  /**
   * Wrapper for moment.js to render a string that indicates how long ago a
   * usecs value occurred from the current cluster time.
   *
   * @param   usecs   The microseconds value to transform
   * @return  The string description of how long its been since provided usecs
   */
  transform(usecs: number, useFloor?: boolean): string {
    if (!usecs || !isFinite(usecs)) {
      return this.intl.naNotAvailable;
    }

    // Store the default relative time rounding function before modifying so
    // that it can be changed back to default. This is needed since moment is
    // being used as a global object.
    const roundingDefault = moment.relativeTimeRounding();
    const secondsThreshold = moment.relativeTimeThreshold('s');

    if (useFloor) {
      /**
       * FromNow rounds off the time to the nearest unit based on the below
       * table. The rounding off can be controlled for each unit along with the
       * function used, using the methods relativeTimeRounding(...) &
       * relativeTimeThreshold(...).
       *
       * RANGE                       KEY    SAMPLE OUTPUT
       * 0 to 44 seconds              s     a few seconds ago
       * unset                        ss    44 seconds ago
       * 45 to 89 seconds             m     a minute ago
       * 90 seconds to 44 minutes     mm    2 minutes ago ... 44 minutes ago
       * 45 to 89 minutes             h     an hour ago
       * 90 minutes to 21 hours       hh    2 hours ago ... 21 hours ago
       * 22 to 35 hours               d     a day ago
       * 36 hours to 25 days          dd    2 days ago ... 25 days ago
       * 26 to 45 days                M     a month ago
       * 45 to 319 days               MM    2 months ago ... 10 months ago
       * 320 to 547 days (1.5 years)  y     a year ago
       * 548 days+                    yy    2 years ago ... 20 years ago
       *
       */
      moment.relativeTimeRounding(Math.floor);

      // Least number of seconds to be considered a min. This is needed to be
      // set since, fromNow(...) doesn't round off seconds difference to its
      // floor as 0.
      moment.relativeTimeThreshold('s', 60);
    }

    // fromNow internally uses the humanize method which uses relative time rounding.
    // Refer https://github.com/moment/moment/blob/develop/moment.js#L4074.
    const humanizedStr = moment(usecs / 1000).fromNow();

    // Reset the rounding function to the default.
    moment.relativeTimeRounding(roundingDefault);
    moment.relativeTimeThreshold('s', secondsThreshold as number);
    return humanizedStr;
  }
}
