import { SelectionModel } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  VIRTUAL_SCROLL_STRATEGY
} from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { AutoDestroyable } from '@cohesity/utils';
import { debounceTime, filter, startWith, share, map } from 'rxjs/operators';
import { Document } from '../folder-browser.models';
import { ObservableInput } from 'ngx-observable-input';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { TableVirtualScrollStrategy } from './table-vs-strategy.service';

/**
 * Should fetch more items when the scroller is within the last 20% of the height.
 */
const scrollLoadThreshold = 0.2;

// Manually set the amount of buffer and the height of the table elements
const bufferSize = 3;
// Height for a table row
const rowHeight = 52;
// Height for a table header
const headerHeight = 56;

/**
 * This component shows a list of files for a directory in a table
 */
@Component({
  selector: 'coh-virtualised-directory-list',
  templateUrl: './virtualised-directory-list.component.html',
  styleUrls: ['./virtualised-directory-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: VIRTUAL_SCROLL_STRATEGY,
    useClass: TableVirtualScrollStrategy,
  }]
})
export class VirtualisedDirectoryListComponent extends AutoDestroyable implements OnInit {
  /**
   * The list of documents to show.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput(null) @Input('documents') documents$: Observable<Document[]>;

  /**
   * Whether there are more documents available to load.
   */
  @Input() isPartialListing = false;

  /**
   * The selection model for the table.
   */
  @Input() selection: SelectionModel<Document>;

  /**
   * To show the loader or not.
   */
  @Input() isLoading = false;

  /**
   * Set true, to only allow folder selection.
   */
  @Input() onlySelectFolders = false;

  /**
   * Callback to decide whether a row item is selectable.
   */
  @Input() canSelectRowFn: (item: Document) => boolean;

  /**
   * Callback to decide whether a row can be clicked to navigate through.
   */
  @Input() canNavigateRowFn: (item: Document) => boolean;

  /**
   * Callback to customize the icon of a row item.
   */
  @Input() rowIconProviderFn: (item: Document) => string;

  /**
   * Event is emitted when a user clicks an anchor row to browse to a directory.
   */
  @Output() browseToPath = new EventEmitter<string>();

  /**
   * This fires whenever isPartialListing is set to true and the use user has scrolled
   * to near the bottom of the table.
   */
  @Output() loadMore = new EventEmitter<void>();

  /**
   * Emit selected item to parent component.
   */
  @Output() selectItem = new EventEmitter<Document>();

  /**
   * Scrollable component for the table
   */
  @ViewChild(CdkVirtualScrollViewport, { static: true }) virtualScrollViewport: CdkVirtualScrollViewport;

  /**
   * Data source for the truncated viryualised rows
   */
  derivedDataSource$ = new BehaviorSubject<Document[]>([]);

  /**
   * Reference to the passed in documents array
   */
  documents: Document[] = [];

  /**
   * Start index of the row rendered
   */
  start: number;

  /**
   * End index of the row rendered
   */
  end: number;

  /**
   * This is used to track whether a parent of this directory is selected, if so, all of the items will
   * show as selected, but disalbed.
   */
  isParentDirectorySelected = false;

  constructor(
    @Inject(VIRTUAL_SCROLL_STRATEGY) private readonly scrollStrategy: TableVirtualScrollStrategy,
    private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    // Initial Height for a table header
    let gridHeight = 300;
    // Determine the initial total number of table rows to be rendered
    let range = Math.ceil(gridHeight / rowHeight) + bufferSize;

    // Set the scroll height
    this.scrollStrategy.setScrollHeight(rowHeight, headerHeight);

    const combinedObservable$ = combineLatest([this.documents$, this.scrollStrategy.scrolledIndexChange])
      .pipe(share());

    // Determine the subset of the documents to be rendered in the table
    // for virtual scrllinf=g
     combinedObservable$.pipe(
      map(([documents, res]) => {
        if (!documents || !documents.length) {
          return [];
        }
        this.documents = documents;
        // Determine actual height for a table
        gridHeight = this.virtualScrollViewport.getElementRef().nativeElement.getBoundingClientRect().height;
        // Determine the total number of table rows to be rendered
        range = Math.ceil(gridHeight / rowHeight) + bufferSize;

        // Determine the start and end rendered range
        this.start = Math.max(0, res - bufferSize);
        this.end = Math.min(documents.length, res + range);

        // Update the datasource for the rendered range of data
        return documents.slice(this.start, this.end);
      }),
    ).subscribe(res => this.derivedDataSource$.next(res));

    // Trigger loadmore when scrolled below the threshold
    combinedObservable$
      .pipe(
        debounceTime(200),
        filter(([documents, res]) => {
          const threshold = (1 - scrollLoadThreshold) * documents.length;
          return (res + range - bufferSize) > threshold;
        }),
        filter(() => !this.isLoading && this.isPartialListing),
        this.untilDestroy()
      )
      .subscribe(() => {
        this.loadMore.emit();
      });

    // Listen for changes to the files and the selection to determine if a parent directory has already
    // been selected. If it has, the checkbox should show as checked, but disabled.
    combineLatest([this.documents$, this.selection.changed.pipe(startWith(this.selection.selected))])
      .pipe(
        map(([documents]) => {
          if (!documents || !documents.length || !this.selection || this.selection.isEmpty()) {
            return false;
          }
          const pathTokens = documents[0].fullPath.split('/');
          pathTokens.pop();
          const parentDirectory = pathTokens.join('/');
          return this.selection.selected.some(selected => parentDirectory.startsWith(selected.fullPath));
        }),
        this.untilDestroy()
      )
      .subscribe(parentSelected => {
        this.isParentDirectorySelected = parentSelected;
        this.cdr.detectChanges();
      });
  }

  /**
   * Use the file path for the table track by function.
   */
  trackBy(index: number, document: Document) {
    return document.fullPath;
  }

  /**
   * Handle selection toggle and update the selection list to remove the child directory/files.
   *
   * @param   row   The row that was selected.
   */
  selectionUpdate(row: Document) {
    const selectedData = this.selection.selected;
    selectedData.forEach(selection => {
      if (selection.fullPath.startsWith(`${row.fullPath}/`)) {
        this.selection.deselect(selection);
      }
    });
    this.selection.toggle(row);
    this.selectItem.emit(row);
  }

  /**
   * Sort the table data on sort event
   */
  sortData(event) {
    if (!event.active || !event.direction) {
      this.derivedDataSource$.next(this.documents.slice(this.start, this.end));
      return;
    }

    this.documents.sort((a, b) => {
      if (event.direction === 'asc') {
         if (a[event.active] < b[event.active]) {
          return -1;
         }
         return 1;
      } else {
        if (a[event.active] > b[event.active]) {
          return -1;
         }
         return 1;
      }
    });

    this.derivedDataSource$.next(this.documents.slice(this.start, this.end));
  }

  /**
   * Handle row click and toggle the selection if the parent directory isn't selected.
   *
   * @param   row   The file row that was clicked.
   */
  onRowClicked(row: Document) {
    if (this.isRowDisabled(row)) {
      return;
    }

    // Disallow the option to select the files if onlySelectFolders is true
    if (!this.onlySelectFolders || row.isFolder) {
      this.selection.toggle(row);
    }
  }

  /**
   * Returns whether the row item should be disabled.
   *
   * @param row The row item
   * @returns A boolean value to indicate whether the row should be disabled.
   */
  isRowDisabled(row: Document): boolean {
    return this.isParentDirectorySelected || !this.canSelectRowFn?.(row);
  }

  /**
   * Returns whether the row item can be clicked to navigate through.
   *
   * @param row The row item
   * @returns A boolean value to indicate whether the item can be navigated through.
   */
  isRowNavigable(row: Document): boolean {
    return row.isFolder && (this.canNavigateRowFn?.(row) ?? true);
  }
}
