import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';

import { KubernetesSourceDataNode } from '../kubernetes-source-data-node';
import { KubernetesPvcFilterType, PvcResourceId, ResourceList, TypeSectionRule } from '../kubernetes.constants';
import { KubernetesQuiesceMode } from '@cohesity/dataprotect/shared';
import { KubernetesLabel } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';

// The limits for the value of timeouts (in minutes).
const timeoutLimits = { min: 1, max: 525_600 /** 1 year */ };

/**
 * Interface for kubernetes pre, post scripts
 */
export interface KubernetesScript {
  /** Container */
  container?: string;

  /** Command to be excuted. */
  command?: string;

  /** Whether to fail on error or not. */
  failOnError: boolean;

  /** Timeout for script. */
  timeout?: number;
}

/**
 * Interface for pvc info
 */
export interface ResourceInfoType {
  /** Name of the pvc */
  name: string;

  /** Id of pvc */
  id: string | number;
}

@Component({
  selector: 'coh-kubernetes-source-options',
  templateUrl: './kubernetes-source-options.component.html',
  styleUrls: ['./kubernetes-source-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class KubernetesSourceOptionsComponent implements DataTreeNodeDetail<KubernetesSourceDataNode>, OnInit {
  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<KubernetesSourceDataNode>;

  /**
   * Expose quiesce mode.
   */
  readonly kubernetesQuiesceMode = KubernetesQuiesceMode;

  /**
   * pvcs of namespace.
   */
  pvcs: ResourceInfoType[];

  /**
   * Make timeout limits available to template.
   */
  timeoutLimits = timeoutLimits;

  /**
   * Type of sections in rules
   */
  typeSectionRule = TypeSectionRule;

  /**
   * Whether to expand rule or not.
   */
  ruleExpand: boolean[] = [];

  /**
   * The list of resources that can be included/excluded
   */
  resourceList: ResourceInfoType[] = ResourceList;

  /**
   * The form to contain the options.
   */
  form: UntypedFormGroup;

  /**
   * Quiesce Rules flag.
   */
  get isKubernetesQuiesceRulesEnabled(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'enableKubernetesQuiesceRules');
  }

  /**
   * Resource inclusion exclusion feature flag
   */
  get isK8sResourceInclusionExclusionEnabled(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'enableK8sResourceInclusionExclusion');
  }

  /**
   * Gets the node from the nodeContext.
   */
  get node(): KubernetesSourceDataNode {
    return this.nodeContext.node;
  }

  /**
   * Gets the current options for the node. This either gets them from the selection options, or
   * the default params for the node.
   */
  get currentOptions() {
    return this.nodeContext.selection.getOptionsForNode(this.node.id) || {};
  }

  /**
   * Gets the list of excluded pvcs, if any, from the special parameters.
   */
  get excludePvcs() {
    return (this.currentOptions.kubernetesSpecialParameters || {}).excludePvcs;
  }

  /**
   * Gets the list of included disks, if any, from the special parameters.
   */
  get includePvcs() {
    return (this.currentOptions.kubernetesSpecialParameters || {}).includePvcs;
  }

  /**
   * Gets quiesce mode, if any, from the special parameters.
   */
  get quiesceMode() {
    return (this.currentOptions.kubernetesSpecialParameters || {}).quiesceMode;
  }

  /**
   * Gets quiesce mode, if any, from the special parameters.
   */
  get quiesceRules() {
    return (this.currentOptions.kubernetesSpecialParameters || {}).quiesceRules;
  }

  /**
   * Gets excluded resources, if any, from the special parameters.
   */
  get excludedResources() {
    return (this.currentOptions.kubernetesSpecialParameters || {}).excludedResources;
  }

  /**
   * Gets included resources, if any, from the special parameters.
   */
  get includedResources() {
    return (this.currentOptions.kubernetesSpecialParameters || {}).includedResources;
  }

  /**
   * Gets backup only pvc value from the special parameters.
   */
  get backupOnlyPvc(): boolean {
    return (this.currentOptions.kubernetesSpecialParameters || {}).backupOnlyPvc;
  }

  /**
   * Get the labels form control.
   *
   * @return   FormArray of labels controls.
   */
  get quiesceRulesControl(): FormArray {
    return this.form.get('quiesceRules') as FormArray;
  }

  /**
   * Get the custom resource form control.
   *
   * @return   FormArray of resource controls.
   */
  get customResourceControl(): FormArray {
    return this.form.get('customResourceList') as FormArray;
  }

  /**
   * Used to check if pvc related resources should be included by default
   * in the resource selection
   *
   * @returns boolean
   */
  isPvcResourceIncludedByDefault(resource: ResourceInfoType): boolean {
    return resource.id === PvcResourceId && this.form.value?.pvcs?.length > 0;
  }

  /**
   * Updates the resource list to include/exclude pvc related resources
   */
  updateResourceList(pvcsPresent: boolean) {
    const resourceListWithoutPvc = ResourceList.filter(resource => resource.id !== PvcResourceId);
    if (pvcsPresent) {
      this.resourceList = resourceListWithoutPvc;
      const currentSelectedResources = this.form.value.resources;
      const updateResources = currentSelectedResources?.filter(resource => resource.id !== PvcResourceId);
      this.form.get('resources')?.setValue(updateResources);
    } else {
      this.resourceList = ResourceList;
    }
  }

  /**
   * On change method when backup only pvc checkbox is clicked
   */
  backupOnlyPvcOnChange() {
    if (this.form.value.backupOnlyPvc) {
      this.form.get('enableResourceFilter')?.setValue(false);
      this.form.get('enableResourceFilter')?.disable();
      this.form.get('resources')?.setValue([]);
      this.customResourceControl?.clear();
    } else {
      this.form.get('enableResourceFilter')?.enable();
    }
  }

  constructor(private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private irisCtx: IrisContextService,
    ) {}

  ngOnInit() {
    // Holds the available pvcs that can be excluded or included.
    this.pvcs = ((this.node.data?.nodes || [])
      .map((node: ProtectionSourceNode) => ({ id: node.protectionSource.id, name: node.protectionSource.name })));
  }

  /**
   * Updates the form based on the current options setting in preparation for displaying the form
   * dialog.
   */
  updateForm() {
    // Map the excluded/included pvc list from the job selection to the actual pvc options
    // that are available for the host.
    let pvcFilterType = KubernetesPvcFilterType.Include;

    if (this.excludePvcs?.length) {
      pvcFilterType = KubernetesPvcFilterType.Exclude;
    }

    const selectedPvcs =
      pvcFilterType === KubernetesPvcFilterType.Include ? this.includePvcs : this.excludePvcs;

    const pvcOptions = (selectedPvcs || [])
      .map(selectedPvc => {
        const { id } = selectedPvc;
        return this.pvcs.find(pvc => pvc.id === id);
      }).filter(Boolean);

    let resourceInclusionType = KubernetesPvcFilterType.Include;
    if (this.excludedResources?.length) {
      resourceInclusionType = KubernetesPvcFilterType.Exclude;
    }

    const resourcesList = this?.includedResources || this?.excludedResources;
    const specificResourceList: ResourceInfoType[] = [];
    const customResourceList: string[] = [];
    if (resourcesList?.length) {
      resourcesList.forEach(resource => {
        const specificResource = ResourceList.find(res => res.id === resource);
        if (specificResource) {
          specificResourceList.push(specificResource);
        } else {
          customResourceList.push(resource);
        }
      });
    }
    const enablePvcFilter = !!pvcOptions.length || this.backupOnlyPvc || false;
    const enableResourceFilter = specificResourceList?.length || customResourceList?.length;

    this.form = this.fb.group({
      enablePvcFilter: [enablePvcFilter, Validators.required],
      pvcInclusionType: [pvcFilterType, Validators.required],
      pvcs: [pvcOptions],
      backupOnlyPvc: [this.backupOnlyPvc],
      enableResourceFilter: [enableResourceFilter, Validators.required],
      resourceInclusionType: [resourceInclusionType, Validators.required],
      resources: [specificResourceList],
      quiesceMode: [this.quiesceMode],
      quiesceRules: new FormArray([]),
      customResourceList: new FormArray([])
    });

    for (let i = 0; i < this.quiesceRules?.length; i++) {
      this.addQuiesceRule();
      this.quiesceRules[i].podSelector?.forEach(label =>
        this.addTypeFormControl(i, this.typeSectionRule.labels, label));
      this.quiesceRules[i].preSnapshotScripts?.forEach(script =>
        this.addTypeFormControl(i, this.typeSectionRule.preScript, script));
      this.quiesceRules[i].postSnapshotScripts?.forEach(script =>
        this.addTypeFormControl(i, this.typeSectionRule.postScript, script));
    }

    this.form.get('pvcs')?.valueChanges.subscribe(pvc => {
      this.updateResourceList(pvc.length);
    });

    customResourceList.forEach(customResource => {
      this.customResourceControl.push(new FormControl(customResource));
    });
    this.backupOnlyPvcOnChange();
    this.updateResourceList(pvcOptions.length);
  }

  /**
   * Updates the selection options after the form has been saved and the dialog has been closed.
   */
  onSaved() {
    const { pvcInclusionType, pvcs, backupOnlyPvc, resourceInclusionType, resources, customResourceList } =
      this.form.value;

    const resourceList = resources?.map(resource => resource.id);
    let includedResources: string[] = [];
    let excludedResources: string[] = [];
    if (resourceInclusionType === KubernetesPvcFilterType.Include) {
      includedResources = [...(resourceList || []), ...(customResourceList || [])];
    }
    if (resourceInclusionType === KubernetesPvcFilterType.Exclude) {
      excludedResources = [...(resourceList || []), ...(customResourceList || [])];
    }

    const options = {
      sourceId: this.node.id,
      kubernetesSpecialParameters: {
        excludePvcs:
          pvcInclusionType === KubernetesPvcFilterType.Exclude && pvcs.length
            ? pvcs.map(pvc => ({
                id: pvc.id,
                name: pvc.name,
              }))
            : undefined,
        includePvcs:
          pvcInclusionType === KubernetesPvcFilterType.Include && pvcs.length
            ? pvcs.map(pvc => ({
                id: pvc.id,
                name: pvc.name,
              }))
            : undefined,
        quiesceMode: this.form.value.quiesceMode,
        quiesceRules: this.form.value.quiesceRules,
        backupOnlyPvc,
        includedResources: includedResources?.length ? includedResources : undefined,
        excludedResources: excludedResources?.length ? excludedResources: undefined,
      },
    };
    this.nodeContext.selection.setOptionsForNode(this.node.id, options);
    this.cdr.detectChanges();
  }

  /**
   * Triggers on toggling pvcInclusionExclusion.
   */
  togglePvcs() {
    if (!this.form.value.enablePvcFilter) {
      this.form.get('pvcs')?.setValue([]);
      this.form.get('backupOnlyPvc')?.setValue(false);
      this.form.get('enableResourceFilter')?.enable();
    }
  }

  /**
   * Called on toggling resource Inclusion'Exclusion.
   */
  toggleResourceInclusion() {
    this.form.get('resources')?.setValue([]);
    this.customResourceControl?.clear();
  }

  /**
   * Triggers on toggling pvcInclusionExclusion type.
   * Reset the include/exclude pvc selection.
   */
  togglePvcInclusionType() {
    this.form.get('pvcs')?.setValue([]);
  }

  /**
   * Remove a certain pvc from the selected pvcs.
   *
   * @params  pvcToRemove is the pvc to be removed.
   */
  removePvc(pvcToRemove: ResourceInfoType) {
    const pvcs = this.form.get('pvcs')?.value;
    this.form.get('pvcs')?.setValue(pvcs.filter(pvc => pvc !== pvcToRemove));
  }

  /**
   * Remove a certain Resource type from the selected resources.
   *
   * @params  pvcToRemove is the pvc to be removed.
   */
  removeAddedResources(resourceToRemove: ResourceInfoType) {
    const resources = this.form.get('resources')?.value;
    this.form.get('resources')?.setValue(resources.filter(resource => resource !== resourceToRemove));
  }

  /**
   * Add new rule.
   */
  addQuiesceRule() {
    this.quiesceRulesControl.push(this.fb.group({
      podSelector: new FormArray([], Validators.required),
      preSnapshotScripts: new FormArray([], Validators.required),
      postSnapshotScripts: new FormArray([], Validators.required),
    }));
    this.ruleExpand.push(true);
  }

  /**
   * Add new custom resource for, control.
   */
  addCustomResourceFormControl() {
    this.customResourceControl.push(new FormControl(''));
  }

  /**
   * Function to remove a form control item from the rules form
   * controls array.
   *
   * @param   index   Index of the item to be removed.
   */
  removeQuiesceRuleControl(index: number) {
    this.quiesceRulesControl.removeAt(index);
    this.ruleExpand.splice(index, 1);
  }

  /**
   * Get the type form control.
   *
   * @param   index   Index of rule.
   * @param   type   Type of section to be retrieved.
   *
   * @return FormArray of type controls.
   */
  typeControl(index: number, type: TypeSectionRule): FormArray {
    const ruleForm = this.quiesceRulesControl.controls[index];
    if (type === this.typeSectionRule.labels) {
      return ruleForm?.get('podSelector') as FormArray;
    }
    return type === this.typeSectionRule.preScript ? ruleForm.get('preSnapshotScripts') as FormArray :
      ruleForm.get('postSnapshotScripts') as FormArray;

  }

  /**
   * Add a new type control.
   *
   * @param   index   Index of rule.
   * @param   type   Type of section to be retrieved.
   * @param   typeValue   Value of type.
   */
  addTypeFormControl(index: number, type: TypeSectionRule, typeValue: (KubernetesLabel | KubernetesScript)) {
    const typeControl = this.typeControl(index, type);
    if (type === 'labels') {
      const {key, value} = typeValue as KubernetesLabel || {};

      typeControl.push(
        new UntypedFormGroup({
          key: new UntypedFormControl(key, Validators.required),
          value: new UntypedFormControl(value, Validators.required)
        })
      );
    } else {
      const {container, command, failOnError, timeout} = typeValue as KubernetesScript || {};
      const timeoutValidators = [
        Validators.pattern('^[0-9]*$'),
        Validators.min(timeoutLimits.min),
        Validators.max(timeoutLimits.max),
      ];

      typeControl.push(
        new UntypedFormGroup({
          container: new UntypedFormControl(container),
          command: new UntypedFormControl(command, Validators.required),
          failOnError: new UntypedFormControl(failOnError),
          timeout: new UntypedFormControl(timeout, timeoutValidators),
        })
      );
    }
  }

  /**
   * Function to remove a form control item from the type form
   * controls array.
   *
   * @param   ruleIndex   Index of the rule to be removed.
   * @param   typeIndex   Index of the type to be removed.
   */
  removeTypeFormControl(ruleIndex: number, typeIndex: number, type: TypeSectionRule) {
    this.typeControl(ruleIndex, type).removeAt(typeIndex);
  }

  /**
   * Function to toggle rule section.
   *
   * @param   index   Index of rule to expand or shrink.
   */
  toggleRuleExpand(index: number){
    this.ruleExpand[index] = !this.ruleExpand[index];
  }

  /**
   * Function to remove a form control form
   * custom resource controls array.
   */
  removeCustomResourceFormControl(index: number) {
    this.customResourceControl.removeAt(index);
  }
}
