import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { SharepointItemMetadataType } from '@cohesity/api/private';
import { ObjectSnapshot, ObjectSummary } from '@cohesity/api/v2';
import { KeyedSelectionModel, SnackBarService } from '@cohesity/helix';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services';
import { Document, Environment, folderBrowserProviderFn, FolderBrowserService, RecoveryAction } from 'src/app/shared';

import { FileSearchResult } from '../../../model/file-search-result';
import { FileSearchResultGroup } from '../../../model/file-search-result-group';
import { RestorePointSelection } from '../../../model/restore-point-selection';
import { FilesService } from '../../../services/files.service';
import { FileListService } from './file-list.service';

/**
 * Inputs for the file browser modal
 */
export interface FileBrowserModalData {
  object: ObjectSummary;

  /**
   * Existing restore point.
   */
  restorePoint: RestorePointSelection;

  /**
   * If this flag is set, the indexing toggle will be hidden. This is mostly for dms.
   */
  hideIndexing: boolean;

  /**
   * Indicates if downloading immediately from the browse modal should be allowed.
   */
  allowDownload: boolean;

  /**
   * Protection source environment which is using the file browser modal component.
   */
  protectionSourceEnvironment?: string;

  /**
   * Object action keys by which to filter the snapshots we can browse files from.
   */
  objectActionKeys?: Environment[];

  /**
   * If this flag is set, the directory list will use a virtualised list.
   */
  useVirtualisedScroll?: boolean;
}

/**
 * Output for the file browser modal.
 */
export interface FileBrowserResult {
  /**
   * The browser action can be either to select the file, or to immediately initiate a download
   */
  action: 'select' | 'download';

  /**
   * The browsers selection.
   */
  selection: RestorePointSelection;
}

/**
 * This is a modal component for a file browser, intended to work with the recovery flow. It's output will be a
 * RestorePointSelection object consisting of a snapshot and file selection.
 */
@Component({
  selector: 'coh-file-browser-modal',
  templateUrl: './file-browser-modal.component.html',
  styleUrls: ['./file-browser-modal.component.scss'],
  providers: [folderBrowserProviderFn(FileListService), FolderBrowserService],
})
export class FileBrowserModalComponent extends AutoDestroyable implements OnInit {

  // Get pagination dropdown values
  paginationArr = [100, 500, 1000, 2500, 5000, 10000];

  /**
   * Currently selected items.
   */
  selection = new KeyedSelectionModel((file: Document) => file.fullPath, true);

  /**
   * The current restore point, derived from the selection and snapshot.
   */
  restorePointSelection: RestorePointSelection;

  // Variable declared to get total records returned by indexed-objects api endpoint.
  totalReturnedRecords = 0;

  /**
   * List of actions available for the snapshot
   */
  snapshotActions: RecoveryAction[];

  /**
   * Object actions keys by which to filter snapshots.
   */
  objectActionKeys: Environment[];

  /**
   * This variable is set to true when we are browsing on O365 adapters.
   */
  isO365Browse = false;

  /**
   * If this flag is set, the directory list will use a virtualised list.
   */
  useVirtualisedScroll = false;

  /**
   * Form control browsing on indexed data or not.
   */
  get browseOnIndexedData() {
    return this.fileListService.browseOnIndexedData;
  }

  /**
   * The currently selected snapshot.
   */
  get currentSnapshot(): ObjectSnapshot {
    return this.fileListService.getCurrentSnapshot();
  }

  /**
   * Returns the currently set point in time value.
   */
  get currentPointInTime(): number {
    return this.fileListService.getPointInTime();
  }

  /**
   * Determines whether the currently supported snapshot supports indexing.
   */
  get indexingAvailable(): boolean {
    return !this.data.hideIndexing && this.currentSnapshot?.indexingStatus === 'Done';
  }

  /**
   * The indexing toggle should be disabled if indexing status isn't complete. It should also be disabled when
   * there is only archived snapshot data available.
   */
  get disableIndexingToggle(): boolean {
    return (
      this.data.hideIndexing ||
      this.currentSnapshot?.indexingStatus !== 'Done' ||
      this.currentSnapshot?.snapshotTargetType === 'Archival'
    );
  }

  /**
   * Check whether the currently selected snapshot is unbrowsable.
   * This should be true for non-indexed archived snapshots, with the excepption that these can be still be browsed on
   * in dmaas, when the hideIndexing flag is set to true.
   */
  get unbrowsable(): boolean {
    return (
      this.currentSnapshot?.snapshotTargetType === 'Archival' &&
      !this.data.hideIndexing &&
      this.currentSnapshot?.indexingStatus !== 'Done'
    );
  }

  /**
   * Decides whether the entry in the directory list is selectable.
   *
   * @param doc The document entry.
   * @returns A boolean value to decide whether the item can be selected.
   */
  canSelectItemInDirectoryListFn: (doc: Document) => boolean = doc =>
    doc.dirEntry?.fstatInfo?.sharepointItemMetadata?.type !== SharepointItemMetadataType.kSiteList;

  /**
   * Decides whether the entry in the directory list is navigable.
   *
   * @param doc The document entry.
   * @returns A boolean value to decide whether the item can be navigated through.
   */
  canNavigateItemInDirectoryListFn: (doc: Document) => boolean = doc => {
    if (doc.dirEntry?.fstatInfo?.sharepointItemMetadata?.type === SharepointItemMetadataType.kSiteList) {
      return false;
    }

    return doc.isFolder;
  };

  directoryListItemIconProviderFn: (doc: Document) => string = doc => {
    if (doc.dirEntry?.fstatInfo?.sharepointItemMetadata?.type === SharepointItemMetadataType.kSiteList) {
      return 'helix:O365-site-list';
    }
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FileBrowserModalData,
    public fbContext: FolderBrowserService,
    private dialogRef: MatDialogRef<FileBrowserModalComponent, FileBrowserResult>,
    private dialogService: DialogService,
    private fileListService: FileListService,
    private filesService: FilesService,
    private snackBarService: SnackBarService,
    private translate: TranslateService
  ) {
    super();

    // Set total records count returned by indexed-objects api endpoint.
    this.fbContext.currentDirectory$.pipe(this.untilDestroy()).subscribe(result => {
      if (result) {
        this.totalReturnedRecords = result.length;
      }
    });
  }

  ngOnInit() {
    this.fileListService.initData(this.data);
    this.restorePointSelection = this.data.restorePoint;
    this.useVirtualisedScroll = this.data.useVirtualisedScroll || false;
    this.snapshotActions = [RecoveryAction.DownloadFilesAndFolders];
    this.objectActionKeys = this.data.objectActionKeys;
    this.isO365Browse = (
      this.restorePointSelection.objectInfo.environment === Environment.kO365);

    const fileSelection = this.data.restorePoint.objectInfo as FileSearchResultGroup;

    const initialSelection = fileSelection.files.map(selected => {
      // The full path always starts with a /. This returns an array of the path tokens where the first
      // item is the volume name. When a volume is slected, there is no / in the path
      const tokens = selected.fullPath.split('/');
      const isVolume = tokens.length === 1;

      return {
        name: selected.name,
        fullPath: selected.fullPath,
        volume: tokens[0],
        type: isVolume ? 'Volume' : (selected.file.type as any),
      };
    });

    this.setCurrentRestorePoint(this.restorePointSelection);
    this.selection.select(...initialSelection);
    this.fbContext.browseToPath();
  }

  /**
   * Validates the selection for download and returns applicable errors
   *
   * @param   selection   Current file selection during browse.
   * @returns An error message if applicable
   */
  getDownloadFilesError(selection): string {
    if (!this.restorePointSelection) {
      return null;
    }

    return this.filesService.validateSelectionForDownload([this.restorePointSelection], selection) || undefined;
  }

  /**
   * Triggered when the browsed on indeed data changes. Show the challenge and either change the setting
   * or keep it as is.
   *
   * @param   change   The slide toggle change event.
   */
  indexedDataChange(change: MatSlideToggleChange) {
    this.changeSnapshotChallenge()
      .pipe(this.untilDestroy())
      .subscribe(confirmed => {
        if (!confirmed) {
          this.fileListService.browseOnIndexedData = !change.checked;
        } else {
          this.fileListService.browseOnIndexedData = change.checked;
          this.resetSelection();
        }
      });
  }

  /**
   * Handle user input from the address bar.
   *
   * @param fullPath The complete path.
   */
  browseToUserInputPath(fullPath: string) {
    const tokens = fullPath.split('/');
    const file = {
      name: tokens[tokens.length - 1],
      fullPath: fullPath,
      volume: tokens[0],
      type: undefined,
    };

    if (this.unbrowsable) {
      if (!this.isO365Browse) {
        this.selection.select(file);
      }
      return;
    }

    // We skip stat call for o365 browse as indexed browse is served by
    // librarian. For non-indexed browse, we need to fetch drive-id to form
    // rocksdb path to make stat call. Therefore, skipping stat call.
    // TODO(Vipul): Fix stat call for indexed browse - ENG-280423
    if (this.isO365Browse) {
      this.fbContext.browseToPath(fullPath);
    } else {
      this.fbContext
      .getFileStat(fullPath, false)
      .pipe(this.untilDestroy())
      .subscribe(stat => {
        if (!stat) {
          this.snackBarService
            .openWithAction(
              this.translate.instant('unableToBrowsePath'),
              this.translate.instant('selectAnyway'),
              'error'
            )
            .subscribe(() => {
              this.selection.select(file);
            });
        } else if (stat?.type === 'Volume' || stat?.type === 'Directory') {
          this.fbContext.browseToPath(fullPath);
        } else {
          file.type = stat.type;
          this.selection.select(file);
          if (tokens.length >= 2) {
            this.fbContext.browseToPath(tokens.slice(0, tokens.length - 1).join('/'));
          }
        }
      });
    }
  }

  /**
   * Saves the dialog with the current selection.
   *
   * @param   action   The selected action, either select or download.
   */
  submit(action: 'select' | 'download') {
    const files = this.selection.selected.map(file => {
      const isVolume = file.type === 'Volume';

      return new FileSearchResult({
        name: isVolume ? '' : file.name,
        path: isVolume ? file.volume : file.fullPath.substr(0, file.fullPath.lastIndexOf('/')),
        inodeId: file.inodeId,

        // For recovery purposes, we can just treat a volume as a directory.
        type: isVolume ? 'Directory' : (file.type as any),
        protectionGroupId: this.currentSnapshot.protectionGroupId,
        storageDomainId: this.currentSnapshot.storageDomainId,
        sourceInfo: this.data.object,
      } as any);
    });

    const objectInfo = new FileSearchResultGroup(files);

    const restorePoint: RestorePointSelection = {
      ...(this.data.restorePoint || {}),
      archiveTargetInfo: this.currentSnapshot.externalTargetInfo,
      objectInfo,
      objectIds: objectInfo.fileIds,
      restorePointId: this.currentSnapshot.id,
      timestampUsecs: this.currentPointInTime || this.currentSnapshot.snapshotTimestampUsecs,
      isPointInTime: !!this.currentPointInTime,
    };

    this.dialogRef.close({
      action: action,
      selection: restorePoint,
    });
  }

  /**
   * This shows a modal whenever the snapshot changes, informing the user that the selection will be cleared.
   * If there are no selected items, this returns immediately.
   *
   * @returns An observable of whether the user has confirmed that their selection should be reset.
   */
  changeSnapshotChallenge(): Observable<boolean> {
    if (this.selection.isEmpty()) {
      return of(true);
    }

    return this.dialogService.simpleDialog(null, {
      title: 'cVmBrowser.selectSnapshotTitle',
      copy: 'cVmBrowser.selectSnapshotText',
      confirmButtonLabel: 'cVmBrowser.selectSnapshotOk',
      declineButtonLabel: 'cancel',
    });
  }

  /**
   * Callback for the "snapshotChanged" event of the snapshot selector menu component.
   */
  onSnapshotChanged(restorePoint: RestorePointSelection) {
    this.changeSnapshotChallenge()
      .pipe(
        this.untilDestroy(),
        filter(confirm => !!confirm)
      )
      .subscribe(() => {
        this.restorePointSelection = restorePoint;
        this.setCurrentRestorePoint(this.restorePointSelection);
        this.resetSelection();
      });
  }

  /**
   * Sets the current snapshot and updates the browse on indexed data value if necessary.
   *
   * @param   snapshot   The current snapshot.
   */
  private setCurrentRestorePoint(restorePoint: RestorePointSelection) {
    this.fileListService.setCurrentSnapShot(restorePoint.snapshot);
    this.fileListService.setPointInTime(restorePoint.isPointInTime ? restorePoint.timestampUsecs : undefined);

    // Force the indexing toggle whenever the selected snapshot only allows indexed or non-indexed
    // browsing.
    if (this.disableIndexingToggle) {
      this.fileListService.browseOnIndexedData = this.indexingAvailable;
    }
  }

  /**
   * Resets the current selection.
   */
  private resetSelection() {
    this.selection.clear();

    // We've separate functions for resetting O365 selections as we avoid
    // making stat call in case of O365 reset. Reset happens in 2 cases -
    // indexed toggle change and snapshot change
    if (this.isO365Browse) {
      this.fbContext.resetO365();
    } else {
      this.fbContext.reset();
    }
  }

  /**
   * Load files and folders on dropdown (Items per page) selection change.
   */
  loadRecordsOnPageChange(event) {
    if (event.target.value && event.target.value !== '') {
      this.fbContext.maxEntries = parseInt(event.target.value, 10);
    }
    this.resetSelection();
  }

  /**
   * Load files and folders on next page button click.
   */
  loadNextPageRecords() {
    this.fbContext.browseToPath(this.fbContext.currentPath, true);
  }
}
