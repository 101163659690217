import { merge } from 'lodash-es';
import { bind } from 'lodash-es';
import { find } from 'lodash-es';
// MODULE: Cluster Summary Page
import { featureEnabled } from "@cohesity/iris-core";

;(function(angular, undefined) {

  angular.module('C.clusterSummary', [])
    .controller('clusterSummaryController', clusterSummaryControllerFn);

  function clusterSummaryControllerFn($rootScope, $scope, $translate, $state,
    cMessage, ClusterService, evalAJAX, DateTimeService, cUtils, ViewBoxService,
    PartitionService, NgClusterService, FEATURE_FLAGS, LinuxUserService, $q,
    PollTaskStatus, NgIrisContextService) {

    var $ctrl = this;
    var pollIntervalSec = 4;

    var durationToMsecsMap = {
      'kHour': 3600000,
      'kDay' : 86400000
    };

    /**
     * True if the user can Upgrade
     */
    $scope.canUpgrade = $rootScope.user.privs.CLUSTER_UPGRADE;

    // Date service
    $scope.preferredDateFormat = DateTimeService.getPreferredDateFormat();

    $scope.clusterInfo = {};

    $scope.loading = true;

    $scope.upgradeCluster = function () {
      var patchV2Enabled = featureEnabled(NgIrisContextService.irisContext, 'patchV2Enhancements')
      var stateToGo = patchV2Enabled ? 'ngCluster-upgrade' : 'cluster-upgrade';
      $state.go(stateToGo);
    };

    /**
     * True if support token is loading.
     */
    $scope.supportTokenLoading = false;

    /**
     * True if there is an error in fetch of support support user token.
     */
    $scope.supportTokenFetchError = false;

    /**
     * Support user key.
     */
    $scope.supportUserKey = undefined;

    $scope.enableModes = [
      {
        kValue: 'kOff',
        contentKey: 'off',
      },
      {
        kValue: 'kTemporaryOn',
        contentKey: 'temporarilyOn',
      },
    ];

    $scope.periodicities = [
      {
        kValue: 'kHour',
        contentKey: 'periodicityOptions.hours',
      },
      {
        kValue: 'kDay',
        contentKey: 'policyModify.days',
      },
    ];

    $scope.supportServerSettings = {
      enabledMode: $scope.enableModes[0],
      frequencyCount: 1,
      enabledPeriodicity: $scope.periodicities[1],
      isEditMode: false,
      rtEndTime: 0,
    };

    $scope.capacityPredictionParams = {
      dateFrom: 0,
      dateTo: 0,
      outOfSpaceMsg: DateTimeService.getNotAvailableFormat(),
    };

    /**
     * Initialization function
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function $onInit() {
      getViewBoxesCount();
      getPartitions();
      getClusterStats();
      // Capacity Predication is only  applicable to Helios SaaS.
      if (NgClusterService.isMcmSaaS) {
        getCapacityPredictionData();
      }
    };

    /**
     * Get support user token needed for support user ssh login.
     */
    function fetchSupportUserKey() {
      return LinuxUserService.generateBashAccessSshKey().toPromise().then(
        function getBashAccessKey(data) {
          $scope.supportUserKey = data.supportUserToken;

          // Set token loading to false once token received is non-zero length.
          if ($scope.supportUserKey.length) {
            $scope.supportTokenLoading = false;
          }
        }, function handleBashAccessError(response) {
          $scope.supportTokenLoading = false;
          $scope.supportTokenFetchError = true;
          evalAJAX.errorMessage(response);
        }
      ).catch(angular.noop);
    }

    /**
     * Run poller until there is an error or token loading is done.
     */
    function isPollerDone() {
      return !!$scope.supportTokenFetchError || !$scope.supportTokenLoading;
    }

    /**
     * Initialization for support server settings.
     *
     * @method    initSupportServerSettings
     */
    function initSupportServerSettings() {
      $scope.supportServerSettings.isEditMode = false;
      $scope.supportServerSettings.enabledMode = $scope.enableModes[0];
      $scope.supportServerSettings.rtEndTime = 0;

      // Check and update supportServerSettings accordingly.
      if ($scope.clusterInfo.reverseTunnelEnabled) {
        // If feature flag is enabled, fetch support token key.
        if (FEATURE_FLAGS.supportUserManagementEnabled &&
          $rootScope.user.privs.SUPPORT_CHANNEL_MODIFY) {
          $scope.supportTokenLoading = true;
          PollTaskStatus.createPoller({
            interval: pollIntervalSec,
            isDoneFn: bind(isPollerDone, this),
            iteratorFn: fetchSupportUserKey,
            maxRetries: 15,
          });
        }
        $scope.supportServerSettings.enabledMode = $scope.enableModes[1];
        $scope.supportServerSettings.rtEndTime =
          $scope.clusterInfo.reverseTunnelEndTimeMsecs;
      } else {
        $scope.supportUserKey = undefined;
      }

      if (FEATURE_FLAGS.kmsEnabled) {
        ClusterService.getExternalKms().then(
          function getKmsConfigSuccess(data) {
            $scope.isExternalKmsConfigured = (data || []).some(
              function eachConfig(config) {
                return config.connectionStatus && config.serverIp;
              });
          });
      }
    }

    function getClusterStats() {
      var params = {
        fetchStats: true
      };
      $scope.loading = true;
      ClusterService.getClusterInfo(params, {}, true).then(
        function getClusterInfoSuccess(response) {

          var localUsagePerfStats = response.data.stats.localUsagePerfStats ||
            {};

          angular.merge($scope.clusterInfo, response.data);

          if(!$scope.clusterInfo.hardwareEncryptionEnabled) {
            $scope.clusterInfo.hardwareEncryptionEnabled = false;
          }

          if (localUsagePerfStats.physicalCapacityBytes &&
            localUsagePerfStats.physicalCapacityBytes !== null && (
              localUsagePerfStats.physicalUsageBytes || localUsagePerfStats
              .systemUsageBytes) && localUsagePerfStats.physicalUsageBytes !==
            null) {
            // TODO: Check for null usecase
            $scope.storageChart.series[0].data = [
              [
                $translate.instant('free'),
                localUsagePerfStats.physicalCapacityBytes -
                  localUsagePerfStats.totalPhysicalUsageBytes,
              ],
              [
                $translate.instant('used'),
                localUsagePerfStats.totalPhysicalUsageBytes,
              ],
            ];
          }
          initSupportServerSettings();
        },
        evalAJAX.errorMessage
      ).finally(
        function getClusterInfoFinally() {
          $scope.loading = false;
        }
      );
    }

    /**
     * Gets the capacity prediction data and renders line chart.
     * This is mcm only feature.
     *
     * @method    getCapacityPredictionData
     */
    function getCapacityPredictionData() {
      ClusterService.getCapacityPredictionStats().then(
        function getCapacityPredictionStatsSuccess(response) {
          var data = response.dataPointVec;
          var rangeData = response.rangePointVec;
          var capacity = response.physicalCapacityBytes;

          // Variables for current date, thresholds and range
          var now = new Date(Date.clusterNow());
          var thresholdVals = {};
          var outOfSpacePoint;
          var predictionTooltip;

          // Check if both data and capacity values are present
          if (data && capacity) {
            thresholdVals = {
              80: (capacity * 80) / 100,
              90: (capacity * 90) / 100,
            };

            // Iterate over data points
            // find the first data point above totalCapacity
            outOfSpacePoint = find(data, function findExceedingPoint(e) {
              return e[1] > capacity;
            });

            // If outOfSpacePoint is defined
            // then assign date to outOfSpaceMsg i.e. Index 0 else more than 90 days msg
            $scope.capacityPredictionParams = {
              dateFrom: data[0][0],
              dateTo: data[data.length - 1][0],
              outOfSpaceMsg: outOfSpacePoint ?
                DateTimeService.formatDate(outOfSpacePoint[0],
                  $scope.preferredDateFormat) :
                $translate.instant('capacityPrediction.outOfSpace.exceedsNinetyDays'),
            };

            // Merge chart object with other settings
            merge($scope.capacityPredictionChart, {
              totalCapacity: capacity,
              xAxis: {
                min: $scope.capacityPredictionParams.dateFrom,
                plotLines: [
                  {
                    value: now,
                  },
                ],
              },
              yAxis: {
                // Adding buffer to yAxis max
                max: capacity + (capacity * 0.1),
                plotLines: [
                  {
                    value: thresholdVals[80],
                    label: {
                      text: '80%',
                    },
                  },
                  {
                    value: thresholdVals[90],
                    label: {
                      text: '90%',
                    },
                  },
                  {
                    value: capacity,
                    label: {
                      text: '100%',
                    },
                  },
                  {
                    value: capacity,
                    label: {
                      text: cUtils.bytesToSize(capacity).string,
                    },
                  },
                ],
              },
              tooltip: {
                shared: true,
                useHTML: true,
                formatter: function tooltipFormatter() {
                  switch (true) {
                    case (this.x <= now):
                      return $translate.instant(
                        'capacityPrediction.tooltip.used', { value: this.y });
                    case (this.x > now):
                      predictionTooltip = $translate.instant(
                        'capacityPrediction.tooltip.prediction', { value: this.y });

                      // Check if both series have rendered
                      // to avoid undefined errors in case range values are not available
                      if (this.points.length === 2) {
                        return predictionTooltip.concat(
                          $translate.instant('capacityPrediction.tooltip.confidenceLevel',
                            {
                              lowerBound: cUtils.bytesToSize(this.points[1].point.low).string,
                              upperBound: cUtils.bytesToSize(this.points[1].point.high).string
                            }));
                      } else {
                        return predictionTooltip;
                      }
                    default:
                      break;
                  }
                },
              },
              series: [
                {
                  data: data,
                  zones: [
                    {
                      value: now,
                    },
                  ],
                },
                {
                  data: rangeData,
                }
              ],
            });
          }
        }
      );
    }

    function getViewBoxesCount() {
      ViewBoxService.getViewBoxes().then(
        function getViewBoxesSuccess(viewBoxes) {
          $scope.clusterInfo.viewBoxCount = viewBoxes.length;

          const encryptedViewboxes =
            viewBoxes?.filter(viewbox=>viewbox?.storagePolicy?.encryptionPolicy
              && viewbox?.storagePolicy?.encryptionPolicy === 'kEncryptionStrong');

          if (FEATURE_FLAGS.encryptionKeySizeDisplay || FEATURE_FLAGS.encryptionAESGCM) {
            $scope.clusterInfo.encryptedViewboxCount = encryptedViewboxes?.length;

            $scope.clusterInfo.encryptedWithAESCBC = encryptedViewboxes?.filter(
              (viewbox) => viewbox?.storagePolicy?.aesEncryptionMode === 'kCBC'
            ).length;

            $scope.clusterInfo.encryptedWithAESGCM = encryptedViewboxes?.filter(
              (viewbox) => viewbox?.storagePolicy?.aesEncryptionMode === 'kGCM'
            ).length;
           }
        },
        evalAJAX.errorMessage
      );
    }

    $scope.successMessage = function successMessage(textKeyContext) {
      cMessage.success({
        textKey: 'copiedToClipboard',
        textKeyContext: textKeyContext,
        }
      );
    }

    /**
     * interfaces the API via PartitionService to determine the number
     * of partitions in the Cluster
     *
     * @method   getPartitions
     */
    function getPartitions() {
      PartitionService.getPartitions().then(
        function getPartitionsSucces(partitions) {
          $scope.clusterInfo.partitionCount = partitions.length;

          // If there are no partitions and user has appropriate
          // privs, encourage the user to create a paritition cMessage.
          if ($rootScope.user.privs.CLUSTER_MODIFY &&
            !$scope.clusterInfo.partitionCount) {
            cMessage.error({
              titleKey: 'partitions.noPartitionsPlea.title',
              textKey: 'partitions.noPartitionsPlea.text',
              acknowledgeTextKey: 'createPartition',
              dismissTextKey: 'cancel',
            }).then(function createPartitionClicked() {
              $state.go('partition', {
                mode: 'new'
              });
            });

          }

          // $scope.partition is only leveraged when
          // FEATURE_FLAGS.multiplePartitions is disabled
          $scope.partition = partitions[0];
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Handles refresh of settings when support server enters into
     * edit mode.
     *
     * @method    editSupportServerSettings
     */
    $scope.editSupportServerSettings = function editSupportServerSettings() {
      $scope.supportServerSettings.isEditMode = true;
      $scope.supportServerEnableEndTimeChange();
    };

    /**
     * interfaces the API via ClusterService to update the support
     * server enable details to the cluster.
     *
     * @method    updateSupportServerInfo
     */
    $scope.updateSupportServerInfo = function updateSupportServerInfo(
      supportServerForm) {

      var params = {
        enableReverseTunnel: false,
        reverseTunnelEnableEndTimeMsecs: 0
      };

      if (supportServerForm.$invalid) {
        return;
      }

      if ($scope.supportServerSettings.enabledMode.kValue !== 'kOff') {
        params.enableReverseTunnel = true;
      }

      if ($scope.supportServerSettings.enabledMode.kValue === 'kTemporaryOn') {
        params.reverseTunnelEnableEndTimeMsecs =
          $scope.supportServerSettings.rtEndTime;
      }

      ClusterService.updateSupportServerInfo(params).then(
        getClusterStats, evalAJAX.errorMessage);
    };

    /**
     * Handles changing the support-server endtime based
     * on selected settings.
     *
     * @method    supportServerEnableEndTimeChange
     */
    $scope.supportServerEnableEndTimeChange =
      function supportServerEnableEndTimeChange() {
        $scope.supportServerSettings.rtEndTime = Date.now() +
          ($scope.supportServerSettings.frequencyCount *
          durationToMsecsMap[
          $scope.supportServerSettings.enabledPeriodicity.kValue]);
      };

    /**
     * Function to cancel editing support server details.
     *
     * @method    cancelSupportServerEdit
     */
    $scope.cancelSupportServerEdit = function cancelSupportServerEdit() {
      initSupportServerSettings();
    };

    /** @type {Object} storage donut configuration object */
    $scope.storageChart = {
      chartType: 'storageDonut',
      titleText: 'totalSize',
      series: [{
        type: 'pie',
        name: 'Storage',
        data: [],
      }],
    };

    /** @type {Object} line_prediction configuration object */
    $scope.capacityPredictionChart = {
      chartType: 'line_prediction',
      chart: {
        backgroundColor: 'transparent'
      },
      series: [{
        data: [],
      }],
    };
  }
})(angular);
